import { ZodError } from 'zod'
import superjson from 'superjson'
import Rollbar from 'rollbar'

export type Err = ZodError | Error | any

function rollbar(): Rollbar | null {
  return process?.env?.ROLLBAR_TOKEN_SERVER
    ? new Rollbar({
      accessToken: process?.env?.ROLLBAR_TOKEN_SERVER,
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
        environment: process?.env?.ENVIRONMENT,
      },
    })
    : null
}

export const flagError = (
  e: Err,
  context: string,
  externalLogger: Rollbar | null = rollbar(),
  payload: object | '' = '',
): { message: string; errors: string } => {
  const errorMessage = e?.message || ''
  const message = `Error in [${context}]`
  const errors =
    e instanceof ZodError
      ? superjson.stringify(e.flatten())
      : e instanceof Error
        ? e.message
        : 'Unknown error'
  const printablePayload = payload || ' -- payload: ' + superjson.stringify(payload)
  console.error(message + ' -- ' + errorMessage + printablePayload, errors)
  externalLogger?.error(message, errors, payload)
  return { message, errors }
}

export const flagLog = (
  message: any,
  externalLogger: Rollbar | null = rollbar(),
  payload: object | '' = '',
): void => {
  externalLogger?.log(message, payload)
  console.log(message, payload)
}
