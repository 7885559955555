<template>
  <div>
    <label :for="id"></label>
    <input
      :id="id"
      type="range"
      class="mb-2 w-full accent-blue"
      :class="[max && 'cursor-pointer']"
      :style="styles"
      min="0"
      :max="max"
      step="1"
      :value="currentValue"
      aria-label="söka"
      @input="updateSlider"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch, toRefs } from 'vue'
const props = defineProps({
  id: String,
  max: { type: Number, default: 100 },
  value: { type: Number, default: 0 },
})

const emit = defineEmits(['update'])

const currentValue = ref(0)

const { value } = toRefs(props)

const updateSlider = (e: Event) => {
  const target = <HTMLInputElement>e.target
  currentValue.value = target.valueAsNumber
  emit('update', target.valueAsNumber)
}

watch(value, () => {
  currentValue.value = value.value
})

const styles = computed(() => {
  let percent = (currentValue.value / props.max) * 100
  return `background: linear-gradient(to right, #00B4D2 ${percent}%, #EBF2F3 ${percent}% )`
})
</script>

<style scoped>
input[type='range'] {
  position: relative;
  display: block;
  height: 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #ebf2f3;
  border-radius: 10px;
  outline: none;
}

input[type='range']::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 8px;
}

input[type='range']::-moz-track {
  -moz-appearance: none;
  height: 8px;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  cursor: pointer;
  background-color: #0088a4;
  border-radius: 50%;
  border: none;
  margin-top: -4px;
}
input[type='range']::-moz-range-thumb {
  -moz-appearance: none;
  height: 15px;
  width: 15px;
  cursor: pointer;
  background-color: #0088a4;
  border-radius: 50%;
  border: none;
  margin-top: -4px;
}
</style>
