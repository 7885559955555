<template>
  <section :class="classes" class="w-full">
    <slot></slot>
  </section>
</template>

<script setup lang="ts">
import { computed } from 'vue'
export interface Props {
  background: 'white' | 'blue' | 'blue-light'
  space?: 'with-space' | 'without-space'
}
const props = withDefaults(defineProps<Props>(), {
  background: 'white',
  space: 'with-space',
})

// Set section background color
const background = computed(() =>
  props.background === 'blue-light' || props.background === 'blue'
    ? 'bg-blue-lighter group is-blue'
    : 'bg-white group is-white',
)

// Set section vertical space
const classes = computed(() => {
  return props.space === 'without-space'
    ? background.value
    : background.value + ' space-y-12 py-12 lg:space-y-24 lg:py-24'
})
</script>
