import { buildUrl } from 'cloudinary-build-url'
import { getRelationships } from './getRelationships'
import { type GetVideoReturnType, getVideo } from './getVideo'
import deepClone from '../../utils/deepClone'

export type getImageCloudinaryReturnType =
  | GetVideoReturnType
  | {
      type: 'image'
      url: string
      alt?: string
      byline?: string
      srcset?: string
    }
  | undefined

export const getImageCloudinary = (
  data: any,
  options?: any,
  included?: any,
  config?: { cloudname: string },
): getImageCloudinaryReturnType => {
  if (!data) return

  const copy = deepClone(data)

  const { id, type } = Array.isArray(copy) && copy.length > 0 ? copy[0] : copy

  if (copy.length === 0) return

  const image = getRelationships(id, included)

  if (type === 'media--remote_video') {
    return getVideo(image)
  }
  const url = buildUrl(image?.attributes?.computed_cloudinary, {
    cloud: {
      cloudName: config?.cloudname,
    },
    ...options,
  })

  return {
    type: 'image',
    url,
    alt: image?.relationships?.field_media_image?.data?.meta?.alt || image?.attributes?.name,
    byline: image?.relationships?.field_media_image?.data?.meta?.title,
    srcset: `${buildUrl(image?.attributes?.computed_cloudinary, {
      cloud: {
        cloudName: config?.cloudname,
      },
      transformations: {
        format: 'webp',
        resize: {
          type: 'fill',
          height: 400,
        },
      },
    })} 720w,
    ${url} 1080w,
    ${url} 1920w
    
    
    `,
  }
}
