<template>
  <svg
    width="229"
    height="146"
    viewBox="0 0 229 146"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.54688 1.10547C10.1118 22.4944 37.7601 71.1385 79.8344 94.6035C132.427 123.935 219.119 103.116 227.961 145.618"
      stroke="#00B4D2"
      stroke-width="2"
      stroke-dasharray="8 8"
    />
  </svg>
</template>
