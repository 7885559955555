<template>
  <div class="block">
    <h3 class="mb-2 font-serif text-2xl font-bold text-black">
      {{
        $t('donationCounter.title', {
          goal: goal.toLocaleString('sv'),
          count: fundraised.toLocaleString('sv'),
          currency: baseCurrency,
        })
      }}
    </h3>
    <div class="mb-2 h-10 w-full rounded-lg bg-grey-light lg:h-12 lg:w-[340px]">
      <div
        class="transition-width h-10 rounded-lg bg-blue duration-1000 lg:h-12"
        :style="percent"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { standardSeries, findNext } from '~/libs/petitions'
import { ref as dbRef, onValue } from 'firebase/database'
import type { Currency, SiteLang } from 'refresh-ui'

const props = defineProps({
  formId: {
    type: String,
    required: true,
  },
  isClosed: {
    type: Boolean,
    required: false,
  },
})

const config = useRuntimeConfig()

const { siteLang } = config.public as {
  siteLang: SiteLang
}

const baseCurrency = computed<Currency>(() => (siteLang === 'sv' ? 'sek' : 'eur'))

// Bind to realtime counter value
const db = useDatabase()
const countRef = dbRef(db, 'sums/' + props.formId)
const data = ref({ startAt: 0, count: 0 })
onValue(countRef, snapshot => {
  data.value = snapshot.val()
})

const fundraised = computed(() => {
  return data.value.startAt + data.value.count
})

// Calculate counter per
const percent = computed(() => 'width:' + (fundraised.value / goal.value) * 100 + '%')

// Calculate goal
const goal = computed(() => {
  return props?.isClosed ? fundraised.value : findNext(fundraised.value, standardSeries)
})
</script>
