<template>
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="19" cy="19" r="19" fill="#00B4D2" />
    <path
      d="M25.9383 19.0685L14.3008 25.7874L14.3008 12.3496L25.9383 19.0685Z"
      fill="white"
    />
  </svg>
</template>
