import { removeCmsDomain } from 'refresh-common'

export default defineNuxtRouteMiddleware(async to => {
  const { fullPath } = to

  if (fullPath.includes('/node/')) {
    const { data } = await useFetch(`/api/redirects?route=${fullPath}`)

    if (data.value.includes('/node/')) return
    const cleanUrl = removeCmsDomain(data.value)

    return navigateTo(cleanUrl)
  }
})
