<template>
  <Header :show-menu-button="false" />
  <div
    v-if="showDashboard"
    class="fixed right-2 top-1/2 z-50 hidden -translate-y-1/2 transform flex-col gap-2 text-center font-sans text-base font-bold text-black lg:flex"
  >
    <a
      v-if="nodeId"
      :href="editUrl"
      class="rounded-lg bg-white px-4 py-2 opacity-30 transition hover:bg-blue-dark hover:text-white hover:opacity-100"
      :title="t('admin.edit.hover')"
      >{{ $t('admin.edit') }}</a
    >
    <a
      v-if="nodeId"
      :href="previewUrl"
      class="rounded-lg bg-white px-4 py-2 opacity-30 transition hover:bg-blue-dark hover:text-white hover:opacity-100"
      :title="t('admin.preview.hover')"
      >{{ $t('admin.preview') }}</a
    >
    <a
      :href="contentUrl"
      class="rounded-lg bg-white px-4 py-2 opacity-30 transition hover:bg-blue-dark hover:text-white hover:opacity-100"
      :title="t('admin.content.hover')"
      >{{ $t('admin.content') }}</a
    >
  </div>
  <template v-if="is404 && nodeId">
    <Container class="mb-16 mt-4 space-y-12 lg:mt-52 lg:space-y-28">
      <IntroBlock :data="unpubData" />
    </Container>
  </template>
  <template v-else-if="is404">
    <NodePage v-if="drupalRes?.data" :resource="drupalRes?.data" />
  </template>
  <template v-else>
    <Container class="mb-16 mt-4 space-y-12 lg:mt-52 lg:space-y-28">
      <IntroBlock :data="errData" />
    </Container>
  </template>
</template>
<script setup lang="ts">
const props = defineProps({
  error: Object,
})

import Header from '@/components/elements/Header.vue'
import { IntroBlock, Container } from 'refresh-ui'
import { ref } from 'vue'
import { useGetResource } from '@/composables/useJsonApi'
import NodePage from '@/components/NodePage.vue'
import { useI18n } from '#imports'

const { t } = useI18n()
const route = useRoute()
const config = useRuntimeConfig()

const drupalRes = ref<any>({})
const previewRes = ref<any>({})

const is404 = ref(false)
const showDashboard = ref(false)

const nodeId = computed(() => previewRes?.value?.data?.data?.attributes?.drupal_internal__nid || 0)

const canonicalValue = computed(() => config.public.siteUrl + route.fullPath)

const editUrl = computed(() => {
  if (!nodeId.value) return ''
  const editPath = `${config.public.cmsBasePath}/node/${nodeId.value}/edit?dr_redirect=${canonicalValue.value}`
  return editPath
})

const contentUrl = computed(() => {
  return `${config.public.cmsBasePath}/admin/content`
})

const previewUrl = computed(() => {
  return canonicalValue.value + '?preview=true'
})
if (props?.error?.statusCode === 404) {
  const { data: data404 } = await useAsyncData<any>('drupalRes', () =>
    useGetResource({
      path: '/404',
    }),
  )

  if (data404?.value?.data) is404.value = true
  drupalRes.value = data404.value
}
const errData = {
  id: 'error',
  subtitle: config.public.siteName,
  title: t('error.title'),
  // TODO: Fix translation
  copy: "<p>Försök gärna ladda sidan igen, eller <a href='/search'>sök här</a>. Om problemet kvarstår, kontakta oss på <a href='mailto:info@djurensratt.se'>info@djurensratt.se</a></p>",
  color: 'black',
}

const unpubData = {
  id: 'error',
  subtitle: config.public.siteName,
  title: t('unpub.title'),
  copy: `<p>Det är bara du som är inloggad som kan se detta. Om du vill kan du <a href='${previewUrl.value}'>förhandsvisa</a> eller <a href='${editUrl.value}'>redigera</a> sidan.</p>`,
  color: 'black',
}

onMounted(async () => {
  const authCookie = useCookie('skey')
  showDashboard.value = Boolean(authCookie?.value)
  if (showDashboard.value) {
    const previewFetch = await useGetResource({
      query: !route.query?.preview ? { ...route.query, ...{ preview: 'true' } } : route.query, // add preview to query if not already there
      params: route.params,
      hash: route.hash,
      path: route.path,
    })
    previewRes.value = previewFetch
    console.log('p', previewRes.value.data?.data?.attributes?.drupal_internal__nid)
  }
})
</script>
