export const standardSeries = [
  500, 1000, 5000, 7500, 10000, 10000, 15000, 15000, 20000, 20000, 25000, 30000, 30000, 35000,
  40000, 40000, 45000, 50000, 50000, 55000, 60000, 60000, 65000, 70000, 75000, 80000, 90000, 90000,
  100000, 100000, 125000, 150000, 175000, 200000, 225000, 250000, 275000, 300000, 350000, 400000,
  450000, 500000, 600000, 700000, 750000, 800000, 850000, 900000, 1000000, 1005000, 1010000,
  1020000, 1030000, 1040000, 1050000, 1060000, 1070000, 1080000, 1090000, 1100000, 1110000, 1120000,
  1130000, 1140000,
].sort((a, b) => a - b) // Making sure the numbers are sorted in ascending order

export const findNext = (current: number, sortedNumbers: number[]): number => {
  let closestLargestNumber = 0
  for (let i = 0; i < sortedNumbers.length; i++) {
    if (sortedNumbers[i] > current) {
      closestLargestNumber = sortedNumbers[i]
      break
    }
  }
  // If no hit on the scale, just return the next 1000 step as a failsafe
  if (closestLargestNumber === 0) return Math.ceil((current + 1) / 1000) * 1000
  return closestLargestNumber
}
