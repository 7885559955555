<template>
  <svg
    width="232"
    height="149"
    viewBox="0 0 232 149"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M230.512 1C222.003 23.0554 194.36 73.1447 151.86 97.0591C98.7341 126.952 10.5697 104.665 1.96087 148.563"
      stroke="#00B4D2"
      stroke-width="2"
      stroke-dasharray="8 8"
    />
  </svg>
</template>
