<template>
  <div class="webforms flex flex-col space-y-2">
    <div class="font-text font-sans text-black lg:text-lg">
      {{ title }}
      <span v-if="required">*</span>
    </div>
    <multiselect
      v-model="value"
      track-by="key"
      label="value"
      placeholder=""
      :name="name"
      :disabled="disabled"
      :required="required"
      :options="[defaultValue, ...sortValues]"
      :show-labels="false"
      :allow-empty="false"
      @select="selectedOption => update(selectedOption, name)"
    />
  </div>
</template>

<script setup>
import { useI18n } from '#imports'
import Multiselect from 'vue-multiselect'

const { t } = useI18n()

import { ref } from 'vue'
const props = defineProps({
  id: String,
  title: String,
  name: String,
  modelValue: String,
  options: Object,
  required: Boolean,
  disabled: { type: Boolean, default: false },
  hidden: { type: Boolean, default: false },
})

const defaultValue = { key: '', value: t('select-country-option') }

const value = ref(defaultValue)

const sortValues = Object.entries(props.options)
  .sort(([, a], [, b]) => {
    return a < b ? -1 : a > b ? 1 : 0
  })
  .map(item => ({ key: item[0], value: item[1] }))

const emit = defineEmits(['update:modelValue', 'setData'])

const update = (selectedOption, key) => {
  emit('setData', { key, value: selectedOption.key })
  emit('update:modelValue', selectedOption.key)
}
</script>

<style>
fieldset[disabled] .multiselect {
  pointer-events: none;
}

.webforms .multiselect,
.webforms .multiselect__input,
.webforms .multiselect__single {
  font-family: inherit;
  font-size: 16px;
  touch-action: manipulation;
}

.webforms .multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #053a41;
}

.webforms .multiselect * {
  box-sizing: border-box;
}

.webforms .multiselect:focus {
  outline: none;
}

.webforms .multiselect--disabled {
  background: #ededed;
  pointer-events: none;
  opacity: 0.6;
}

.webforms .multiselect--active {
  z-index: 50;
}

.webforms .multiselect--active:not(.multiselect--above) .multiselect__current,
.webforms .multiselect--active:not(.multiselect--above) .multiselect__input,
.webforms .multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

/* .multiselect--active .multiselect__select {
  transform: rotateZ(180deg);
} */

.webforms .multiselect--above.multiselect--active .multiselect__current,
.webforms .multiselect--above.multiselect--active .multiselect__input,
.webforms .multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.webforms .multiselect__input,
.webforms .multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 16px;
  line-height: 16px;
  border: none;
  outline: 0;
  border: 0;
  box-shadow: none;
  outline-offset: 0;
  padding: 0;

  background: #fff;
  width: calc(100%);
  transition: border 0.1s ease;
  box-sizing: border-box;
  vertical-align: top;
}

@media (min-width: 1024px) {
  .webforms .multiselect__input,
  .webforms .multiselect__single {
    min-height: 20px;
    line-height: 20px;
    font-size: 20px;
  }
}

.webforms .multiselect__input::placeholder {
  color: #35495e;
}

.webforms .multiselect__tag ~ .multiselect__input,
.webforms .multiselect__tag ~ .multiselect__single {
  width: auto;
}

.webforms .multiselect__input:focus,
.webforms .multiselect__single:focus {
  outline: none;
  box-shadow: none;
}

.webforms .multiselect__tags-wrap {
  display: inline;
}

.webforms .multiselect__tags {
  height: 42px;
  padding: 12px 30px 12px 12px;
  border-radius: 6px;
  border: 1px solid #0088a4;
  background: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
}

@media (min-width: 1024px) {
  .webforms .multiselect__tags {
    height: 54px;
    padding: 16px 40px 16px 16px;
  }
}

.webforms .multiselect--disabled .multiselect__tags,
.webforms .multiselect--disabled .multiselect__single {
  background: #d8d8d8;
}

.webforms .multiselect--disabled .multiselect__tags {
  border-color: #adbacb;
}

.webforms .multiselect__select {
  line-height: 16px;
  display: block;
  position: absolute;
  box-sizing: border-box;
  right: 8px;
  top: 9px;
  margin: 0;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease;
}

@media (min-width: 1024px) {
  .webforms .multiselect__select {
    top: 12px;
  }
}

.webforms .multiselect__select::before {
  width: 24px;
  height: 24px;
  position: relative;
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'none'%20viewBox%3D'0%200%2020%2020'%3E%3Cpath%20stroke%3D'%236b7280'%20stroke-linecap%3D'round'%20stroke-linejoin%3D'round'%20stroke-width%3D'1.5'%20d%3D'M6%208l4%204%204-4'%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-size: 24px;
  content: '';
}

@media (min-width: 1024px) {
  .webforms .multiselect__select::before {
    width: 30px;
    height: 30px;
    background-size: 30px;
  }
}

.webforms .multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}

.webforms .multiselect--active .multiselect__placeholder {
  display: none;
}

.webforms .multiselect__content-wrapper {
  position: absolute;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 50;
  -webkit-overflow-scrolling: touch;
}

.webforms .multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top;
}

.webforms .multiselect--above .multiselect__content-wrapper {
  bottom: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #e8e8e8;
}

.webforms .multiselect__content::-webkit-scrollbar {
  display: none;
}

.webforms .multiselect__element {
  display: block;
}

.webforms .multiselect__option {
  display: block;
  padding: 12px;
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}

.webforms .multiselect__option::after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
  font-size: 13px;
}

.webforms .multiselect__option--highlight {
  background: #0088a4;
  outline: none;
  color: white;
}

.webforms .multiselect__option--highlight::after {
  content: attr(data-select);
  background: #0088a4;
  color: white;
}

.webforms .multiselect__option--selected {
  background: #0088a4;
  color: white;
  font-weight: bold;
}

.webforms .multiselect__option--selected::after {
  content: attr(data-selected);
  color: silver;
  background: inherit;
}

.webforms .multiselect__option--selected.multiselect__option--highlight {
  background: #0088a4;
  color: #fff;
}

.webforms .multiselect__option--selected.multiselect__option--highlight::after {
  background: #0088a4;
  content: attr(data-deselect);
  color: #fff;
}

.webforms .multiselect--disabled .multiselect__current,
.webforms .multiselect--disabled .multiselect__select {
  background: #ededed;
  color: #a6a6a6;
}

.webforms .multiselect__option--disabled {
  background: #ededed !important;
  color: #a6a6a6 !important;
  cursor: text;
  pointer-events: none;
}

.webforms .multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede;
}

.webforms .multiselect__option--group-selected.multiselect__option--highlight {
  background: #0088a4;
  color: #fff;
}

.webforms .multiselect__option--group-selected.multiselect__option--highlight::after {
  background: #0088a4;
  content: attr(data-deselect);
  color: #fff;
}

.webforms .multiselect-enter-active,
.webforms .multiselect-leave-active {
  transition: all 0.15s ease;
}

.webforms .multiselect-enter,
.webforms .multiselect-leave-active {
  opacity: 0;
}

.webforms .multiselect__strong {
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}
</style>
