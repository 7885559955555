<template>
  <div :class="twMerge('flex flex-col space-y-2', styleInput)">
    <div class="font-text font-sans text-black lg:text-lg">
      {{ title }}
      <span v-if="required">*</span>
    </div>

    <multiselect
      v-model="value"
      track-by="key"
      label="label"
      :placeholder="placeholder"
      :name="name"
      :disabled="disabled"
      :hidden="hidden"
      :required="required"
      :options="[...defaultValue, ...sortValues]"
      :show-labels="false"
      :allow-empty="false"
      :openDirection="openDirection"
      :maxHeight="maxHeight"
      @update:modelValue="updateValue"
    />
    <div
      v-if="v$?.$invalid && v$?.$dirty && v$?.$errors?.length > 0"
      :class="
        twMerge(
          'mt-0.5 flex w-full flex-col items-start rounded px-1 py-0 pr-3 font-sans text-sm text-pink',
          styleError,
        )
      "
      role="alert"
    >
      <span v-for="err in v$.$errors" :key="err.$uid">{{ err.$message }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import Multiselect from 'vue-multiselect'
import { useVModel } from '@vueuse/core'
import { twMerge } from 'tailwind-merge'
import type { Validation } from '@vuelidate/core'

const { t } = useI18n()

interface ISelectProps {
  id?: string
  title: string
  name?: string
  modelValue: string | number
  options: any
  required?: boolean
  disabled?: boolean
  hidden?: boolean
  styleInput?: string
  styleError?: string
  placeholder?: string
  openDirection?: 'top' | 'bottom'
  maxHeight?: number
  v$?: Validation
}

const props = withDefaults(defineProps<ISelectProps>(), {
  hidden: false,
  disabled: false,
  placeholder: '',
})

const defaultValue = [{ key: '', label: t('select-country-option') }]

const sortValues = Object.entries(props.options)
  .sort(([, a], [, b]) => {
    return a < b ? -1 : a > b ? 1 : 0
  })
  .map(([key, label]) => ({ key, label }))

const emit = defineEmits(['update:modelValue'])

const selected = useVModel(props, 'modelValue', emit)

const value = computed(() => {
  const val = Object.entries(props.options)
    .filter(([key, value]) => key === selected.value)
    .map(([key, label]) => ({ key, label }))

  if (val.length > 0) {
    return val
  }

  return [{ key: '', label: t('select-country-option') }]
})

const updateValue = (value: any) => {
  selected.value = value.key
  props.v$ && props.v$.$validate()
}
</script>

<style>
fieldset[disabled] .multiselect {
  pointer-events: none;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: 16px;
  touch-action: manipulation;
}

.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  min-height: 40px;
  text-align: left;
  color: #053a41;
}

.multiselect * {
  box-sizing: border-box;
}

.multiselect:focus {
  outline: none;
}

.multiselect--disabled {
  background: #ededed;
  pointer-events: none;
  opacity: 0.6;
}

.multiselect--active {
  z-index: 50;
}

.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.multiselect__input,
.multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 16px;
  line-height: 16px;
  border: none;
  outline: 0;
  border: 0;
  box-shadow: none;
  outline-offset: 0;
  padding: 0;

  background: #fff;
  width: 100%;
  transition: border 0.1s ease;
  box-sizing: border-box;
  vertical-align: top;
}

@media (min-width: 1024px) {
  .multiselect__input,
  .multiselect__single {
    min-height: 20px;
    line-height: 20px;
    font-size: 20px;
  }
}

.multiselect__input::placeholder {
  color: #35495e;
}

.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single {
  width: auto;
}

.multiselect__input:focus,
.multiselect__single:focus {
  outline: none;
  box-shadow: none;
}

.multiselect__tags-wrap {
  display: inline;
}

.multiselect__tags {
  height: 42px;
  padding: 12px 30px 12px 12px;
  border-radius: 6px;
  border: 1px solid #0088a4;
  background: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
}

@media (min-width: 1024px) {
  .multiselect__tags {
    height: 60px;
    padding: 16px 40px 16px 16px;
  }
}

.multiselect__select {
  line-height: 16px;
  display: block;
  position: absolute;
  box-sizing: border-box;
  right: 8px;
  top: 9px;
  margin: 0;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease;
}

@media (min-width: 1024px) {
  .multiselect__select {
    top: 16px;
  }
}

.multiselect__select::before {
  width: 24px;
  height: 24px;
  position: relative;
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'none'%20viewBox%3D'0%200%2020%2020'%3E%3Cpath%20stroke%3D'%236b7280'%20stroke-linecap%3D'round'%20stroke-linejoin%3D'round'%20stroke-width%3D'1.5'%20d%3D'M6%208l4%204%204-4'%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-size: 24px;
  content: '';
}

@media (min-width: 1024px) {
  .multiselect__select::before {
    width: 30px;
    height: 30px;
    background-size: 30px;
  }
}

.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}

.multiselect--active .multiselect__placeholder {
  display: none;
}

.multiselect__content-wrapper {
  position: absolute;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 50;
  -webkit-overflow-scrolling: touch;
}

.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top;
}

.multiselect--above .multiselect__content-wrapper {
  bottom: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #e8e8e8;
}

.multiselect__content::-webkit-scrollbar {
  display: none;
}

.multiselect__element {
  display: block;
}

.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}

.multiselect__option::after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
  font-size: 13px;
}

.multiselect__option--highlight {
  background: #0088a4;
  outline: none;
  color: white;
}

.multiselect__option--highlight::after {
  content: attr(data-select);
  background: #0088a4;
  color: white;
}

.multiselect__option--selected {
  background: #0088a4;
  color: white;
  font-weight: bold;
}

.multiselect__option--selected::after {
  content: attr(data-selected);
  color: silver;
  background: inherit;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #0088a4;
  color: #fff;
}

.multiselect__option--selected.multiselect__option--highlight::after {
  background: #0088a4;
  content: attr(data-deselect);
  color: #fff;
}

.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: #ededed;
  color: #a6a6a6;
}

.multiselect__option--disabled {
  background: #ededed !important;
  color: #a6a6a6 !important;
  cursor: text;
  pointer-events: none;
}

.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede;
}

.multiselect__option--group-selected.multiselect__option--highlight {
  background: #0088a4;
  color: #fff;
}

.multiselect__option--group-selected.multiselect__option--highlight::after {
  background: #0088a4;
  content: attr(data-deselect);
  color: #fff;
}

.multiselect-enter-active,
.multiselect-leave-active {
  transition: all 0.15s ease;
}

.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}

.multiselect__strong {
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}
</style>
