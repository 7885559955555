<template>
  <div class="flex items-center">
    <input
      :id="name + formId"
      :name="name"
      :checked="checked"
      type="checkbox"
      :hidden="hidden"
      :disabled="disabled"
      :read-only="readOnly"
      :required="required"
      class="mr-2 h-7 w-7 appearance-none rounded outline-0 checked:bg-blue hover:checked:bg-blue-dark focus:ring-0 focus:checked:bg-blue disabled:border-[#ADBACB] disabled:bg-[#D8D8D8] lg:h-8 lg:w-8"
      @input="e => update(e, name)"
    />
    <label :for="name + formId" class="text-l5 block text-base leading-normal lg:text-lg">
      {{ label }}
    </label>
  </div>
</template>

<script setup>
defineProps({
  label: String,
  modelValue: [Number],
  checked: Boolean,
  name: String,
  required: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  hidden: { type: Boolean, default: false },
  readOnly: { type: Boolean, default: false },
  formId: String,
})
const emit = defineEmits(['update:modelValue', 'setData'])

const update = (e, key) => {
  emit('setData', { key, value: e.target.checked })

  emit('update:modelValue', e.target.checked === true ? 1 : 0)
}
</script>
