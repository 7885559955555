<template>
  <input :value="modelValue" :name="name" type="hidden" />
</template>

<script setup>
import { onMounted } from 'vue';

const props = defineProps({
  defaultValue: [String, Number],
  modelValue: [String, Number],
  name: String,
});

const emit = defineEmits(['update:modelValue', 'setData']);

onMounted(() => {
  // console.log('emitting update:modelValue: ', props.defaultValue);
  // console.log('emitting setData, ', { key: props.name, value: props.defaultValue });
  emit('setData', { key: props.name, value: props.defaultValue });
  emit('update:modelValue', props.defaultValue);
});
</script>
