import { reactive } from 'vue'
import type { Ref } from 'vue'

interface SpotifyStore {
  embedController: any
  initialized: boolean
  currentSeconds: number
  duration: number
  isPaused: boolean
  isBuffering: boolean
  currentPodcast: string
  added: boolean
  location: string
  IFrameAPI: Ref<any> | null
}

export const spotifyStore: SpotifyStore = reactive({
  embedController: null,
  initialized: false,
  currentSeconds: 0,
  duration: 0,
  isPaused: true,
  isBuffering: false,
  currentPodcast: '',
  added: false,
  location: '',
  IFrameAPI: null,
})
