<script setup lang="ts">
import LogoSwish from '../atoms/LogoSwish.vue'
import { Button, LoaderSpinner } from 'refresh-ui'
import { useTimeout } from '@vueuse/core'

interface Props {
  qr?: string
  showQrCode: boolean
  showLoader: boolean
  swishAppUrl?: string
  showLink: boolean
  showRetry: boolean
}
const props = withDefaults(defineProps<Props>(), {
  showLoader: true,
  showQrCode: false,
})

defineEmits(['onRetry', 'onClose'])

const openLink = () => window.open(props.swishAppUrl)

//show text after 15sec
const showReturn = useTimeout(15000)
</script>

<template>
  <div class="fixed bottom-0 left-0 right-0 top-0 z-50 h-screen w-full overflow-hidden">
    <div class="h-[100svh]">
      <div class="absolute h-full w-full bg-white opacity-90"></div>
      <div class="absolute right-5 top-5 z-20 cursor-pointer p-4" @click="$emit('onClose')">
        <AtomsIconsClose />
      </div>
      <template v-if="showLoader && !showRetry">
        <div class="relative z-10 flex h-full w-full flex-col items-center justify-center">
          <LogoSwish class="mb-[50px] h-[250px]" />
          <LoaderSpinner class="m-0 mb-[50px] h-[250px]" />
          <h2 class="text-center text-xl font-bold text-black">Öppna Swish-appen</h2>
          <p class="text-center text-xl text-black">på din enhet</p>
          <p class="mx-4 mt-4 text-blue" v-if="showReturn">
            Det här tar lång tid, behöver du
            <span class="undeline cursor-pointer font-bold underline" @click="$emit('onRetry')">
              försöka igen
            </span>
            eller
            <span class="undeline cursor-pointer font-bold underline" @click="$emit('onClose')">
              korrigera ditt telefonnummer</span
            >?
          </p>
        </div>
      </template>
      <template v-if="showQrCode && !showRetry">
        <div class="relative z-10 flex h-full w-full flex-col items-center justify-center">
          <LogoSwish class="mb-[50px] h-[250px]" />
          <img v-if="qr" class="mb-[50px] h-[250px]" :src="qr" alt="" />
          <LoaderSpinner class="m-0 mb-[50px] h-[250px]" v-else />
          <p class="text-center text-xl text-black">Öppna din Swish-app och</p>
          <h2 class="text-center text-xl font-bold text-black">Skanna denna QR-kod</h2>
        </div>
      </template>
      <template v-if="showLink && !showRetry">
        <div class="relative z-10 flex h-full w-full flex-col items-center justify-center">
          <LogoSwish class="mb-[50px] h-[175px]" />
          <LoaderSpinner class="m-0 mb-[50px] h-[175px]" />
          <Button title="Öppna Swish" :is-loading="!swishAppUrl" @click="openLink" class="px-6" />
        </div>
      </template>
      <template v-if="showRetry">
        <div class="relative z-10 flex h-full w-full flex-col items-center justify-center">
          <LogoSwish class="mb-[50px] h-[175px]" />
          <h2 class="text-center text-xl font-bold text-black">Betalningen avbröts</h2>
          <Button title="Försök igen" @click="$emit('onRetry')" class="my-12 px-6" />
          <p class="mt-4 text-blue">
            eller
            <span class="undeline cursor-pointer font-bold" @click="$emit('onClose')">
              gå tillbaka
            </span>
            och justera
          </p>
        </div>
      </template>
    </div>
  </div>
</template>
