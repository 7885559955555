<template>
  <svg
    width="638"
    height="91"
    viewBox="0 0 638 91"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.75 1C37.4167 24.8333 139.65 75.4 255.25 87C399.75 101.5 583.25 36 637.25 87"
      stroke="#00B4D2"
      stroke-width="2"
      stroke-dasharray="8 8"
    />
  </svg>
</template>
