<template>
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="19" cy="19" r="19" fill="#434343" />
    <path d="M17.1003 12.666H13.9336V25.3327H17.1003V12.666Z" fill="white" />
    <path d="M24.3836 12.666H21.2169V25.3327H24.3836V12.666Z" fill="white" />
  </svg>
</template>
