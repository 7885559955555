<template>
  <div class="h-full w-full overflow-hidden">
    <div class="relative">
      <div v-for="(step, index) in stepsArray" :id="step + index" :key="index">
        <transition :name="transitionName">
          <div v-show="activeStepIndex === index">
            <slot :name="stepsArray[index]" :next-step="nextStep" :prev-step="prevStep" />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';

interface IStep {
  name: string;
}

interface ISteps {
  [key: string]: IStep;
}

const props = defineProps<{ steps: ISteps }>();

const TRANSITIONS = {
  NEXT: 'step-next',
  PREV: 'step-prev',
};

const activeStepIndex = ref(0);
const transitionName = ref('step-next');

const stepsArray = computed(() => {
  return Object.entries(props.steps).map(el => el[0]);
});

const nextStep = () => {
  transitionName.value = TRANSITIONS.NEXT;
  if (activeStepIndex.value === stepsArray.value.length - 1) {
    return;
  }
  activeStepIndex.value++;
};

const prevStep = () => {
  transitionName.value = TRANSITIONS.PREV;
  if (activeStepIndex.value === 0) {
    return;
  }
  activeStepIndex.value--;
};
</script>

<style>
.step-next-enter-active,
.step-next-leave-active,
.step-prev-enter-active,
.step-prev-leave-active {
  transition: transform 0.5s ease-in-out;
}
.step-next-enter-from {
  transform: translateX(100%);
}

.step-next-enter-active {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.step-next-leave-to {
  transform: translateX(-100%);
}

.step-prev-enter-from {
  transform: translateX(-100%);
}

.step-prev-enter-active {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.step-prev-leave-to {
  transform: translateX(100%);
}
</style>
