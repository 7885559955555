export const INTRO_BLOCK = 'paragraph--content_intro';
export const CTA_BANNER_BLOCK = 'paragraph--content_cta_banner';
export const TALL_CARD_SLIDER_BLOCK = 'paragraph--content_tall_card_slider';
export const VIDEO_BLOCK = 'paragraph--content_video';
export const FACTS_BLOCK = 'paragraph--content_facts';
export const INFO_FORM_BLOCK = 'paragraph--content_info_form';
export const CONTENT_LIST_BLOCK = 'paragraph--content_list';
export const SHORT_ZEBRA_BLOCK = 'paragraph--content_short_zebra';
export const TIMELINE_SLIDER_BLOCK = 'paragraph--content_timeline_slider';
export const LIST_BLOCK = 'paragraph--content_list';
export const IMAGE_TEXT_BLOCK = 'paragraph--content_media_text';
export const CARD_ROW_BLOCK = 'paragraph--content_card_row';
export const IMAGE_BLOCK = 'paragraph--content_image';
export const NEWS_CARD_SLIDER_BLOCK = 'paragraph--content_news_card_slider';
export const TINY_SLIDER_BLOCK = 'paragraph--content_tiny_slider';
export const TESTIMONIAL_BLOCK = 'paragraph--content_testimonial';
export const EXPANDABLE_BLOCK = 'paragraph--content_expandable_sources';
export const BIG_ARGUMENT_BLOCK = 'paragraph--content_big_argument';
export const TEXT_BLOCKS = 'paragraph--block_text_blocks';
export const FAQ_BLOCK = 'paragraph--content_faq';
export const COMPACT_NEWS_BLOCK = 'paragraph--content_compact_news';
export const PODCAST_BLOCK = 'paragraph--content_podcast';
export const TABS_BLOCK = 'paragraph--content_tabs';
export const BIG_CTA_BLOCK = 'paragraph--content_big_cta';
export const ON_THIS_PAGE_BLOCK = 'paragraph--content_on_this_page';
export const CONTENT_HTML_BLOCK = 'paragraph--content_html';
export const CONTENT_TEXT_BLOCK = 'paragraph--content_text_block';
export const SMALL_IMAGE_GRID_BLOCK = 'paragraph--content_small_image_grid_cards';
export const SEARCH_STRINGS_BLOCK = 'paragraph--content_search_strings';
export const LIST_AUTO_BLOCK = 'paragraph--content_list_auto';
export const PETITION_BLOCK = 'paragraph--content_petition';
export const TITLE_TEXT_BLOCK = 'paragraph--content_title_text';

export const SECTION_BLOCK = 'paragraph--section';

export const inputFields = ['tel', 'email', 'textfield', 'date'];
