import { defineNuxtPlugin, useRuntimeConfig } from '#app';
import Rollbar from 'rollbar';

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  const rollbar = new Rollbar({
    accessToken: config.public.ROLLBAR_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: config.public.ENVIRONMENT,
    },
  });
  return {
    provide: {
      rollbar: rollbar,
    },
  };
});
