<template>
  <svg
    width="638"
    height="90"
    viewBox="0 0 638 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M636.5 1C599.92 24.793 497.927 75.2741 382.6 86.8544C238.44 101.33 55.3727 35.9408 1.50001 86.8544"
      stroke="#00B4D2"
      stroke-width="2"
      stroke-dasharray="8 8"
    />
  </svg>
</template>
