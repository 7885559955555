<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9998 13.408L18.2898 19.708C18.4781 19.8963 18.7335 20.0021 18.9998 20.0021C19.2661 20.0021 19.5215 19.8963 19.7098 19.708C19.8981 19.5197 20.0039 19.2643 20.0039 18.998C20.0039 18.7317 19.8981 18.4763 19.7098 18.288L13.4098 11.998L19.7098 5.70799C19.8981 5.51968 20.0039 5.26429 20.0039 4.99799C20.0039 4.73168 19.8981 4.47629 19.7098 4.28799C19.5215 4.09968 19.2661 3.99389 18.9998 3.99389C18.7335 3.99389 18.4781 4.09968 18.2898 4.28799L11.9998 10.588L5.70981 4.28799C5.61685 4.19426 5.50625 4.11986 5.38439 4.06909C5.26253 4.01833 5.13183 3.99219 4.99981 3.99219C4.8678 3.99219 4.7371 4.01833 4.61524 4.06909C4.49338 4.11986 4.38278 4.19426 4.28982 4.28799C4.19609 4.38095 4.12169 4.49155 4.07092 4.61341C4.02015 4.73527 3.99402 4.86597 3.99402 4.99799C3.99402 5.13 4.02015 5.2607 4.07092 5.38256C4.12169 5.50442 4.19609 5.61502 4.28982 5.70799L10.5898 11.998L4.28982 18.288C4.19609 18.381 4.12169 18.4916 4.07092 18.6134C4.02015 18.7353 3.99402 18.866 3.99402 18.998C3.99402 19.13 4.02015 19.2607 4.07092 19.3826C4.12169 19.5044 4.19609 19.615 4.28982 19.708C4.38278 19.8017 4.49338 19.8761 4.61524 19.9269C4.7371 19.9776 4.8678 20.0038 4.99981 20.0038C5.13183 20.0038 5.26253 19.9776 5.38439 19.9269C5.50625 19.8761 5.61685 19.8017 5.70981 19.708L11.9998 13.408Z"
      fill="currentColor"
    />
  </svg>
</template>
