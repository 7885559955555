import deepClone from '../../utils/deepClone'
import { getImageCloudinary, type getImageCloudinaryReturnType } from './getImageCloudinary'
import { type GetVideoReturnType, getVideo } from './getVideo'

export type getMediaReturnType = getImageCloudinaryReturnType | GetVideoReturnType

export const getMedia = (data: any, config?: any): getMediaReturnType => {
  if (!data) return
  const copy = deepClone(data)
  const type = Array.isArray(copy) && copy.length > 0 ? copy[0].type : copy?.type
  if (type === 'media--image') {
    return getImageCloudinary(
      data,
      {
        transformations: {
          format: 'webp',
          resize: {
            type: 'fill',
            height: 1024,
          },
        },
      },
      {},
      { cloudname: config.cloudname },
    )
  }
  if (type === 'media--remote_video') {
    return getVideo(data)
  }
}
