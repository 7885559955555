<template>
  <header class="bg-white py-3.5 lg:py-4">
    <Container class="flex items-center justify-between">
      <NuxtLink to="/" class="cursor-pointer" aria-label="Home">
        <Logo v-if="lang === 'sv'" class="h-[38px] w-[95px] lg:h-auto lg:w-auto" />
        <LogoUk v-else-if="lang === 'uk'" class="h-[38px] w-[95px] lg:h-auto lg:w-auto" />
        <LogoEn v-else-if="lang === 'en'" class="h-[38px] w-[95px] lg:h-auto lg:w-auto" />
        <LogoEn v-else-if="lang === 'es'" class="h-[38px] w-[95px] lg:h-auto lg:w-auto" />
      </NuxtLink>
      <div class="flex items-center">
        <!-- Mobile > Support us -->
        <Button
          v-if="showMenuButton && langConf.show.support"
          :title="$t('support-us')"
          color="pink"
          class-name-text="text-base leading-snug lg:leading-snug"
          class="mr-2 rounded-md px-8 py-2 lg:hidden"
          :aria-label="$t('support-us')"
          @click="openSupport"
        />

        <!-- Mobile > Donate -->
        <Button
          v-if="showMenuButton && langConf.show.onlyDonate"
          :title="$t('one-time-gift')"
          color="pink"
          class-name-text="text-base leading-snug lg:leading-snug"
          class="mr-2 rounded-md px-8 py-2 lg:hidden"
          :aria-label="$t('one-time-gift')"
          @click="openDonate"
        />

        <!-- Desktop > Member -->
        <Button
          v-if="showMenuButton && langConf.show.membership"
          :title="$t('become-a-member')"
          color="pink"
          class-name-text="text-base leading-snug lg:leading-snug"
          class="mr-2 hidden rounded-md !py-2 px-8 lg:flex lg:rounded-md lg:!py-2 lg:px-8"
          :aria-label="$t('become-a-member')"
          @click="openMember"
        />

        <!-- Desktop > Donate -->
        <Button
          v-if="showMenuButton && langConf.show.donate"
          :title="$t('one-time-gift')"
          :aria-label="$t('one-time-gift')"
          class-name-text="text-base !leading-snug lg:leading-snug"
          class="hidden rounded-md !py-2 px-8 lg:flex lg:rounded-md lg:px-8 lg:py-2"
          @click="openDonate"
        />

        <div class="ml-10 hidden lg:flex">
          <!-- Desktop > Search  -->
          <button
            v-if="showMenuButton && !config.public.hideSearch"
            class="mr-4 hidden lg:block"
            aria-label="Search"
            @click="openSearch"
          >
            <Search />
          </button>

          <!-- Desktop > Menu  -->
          <button
            v-if="showMenuButton && !config.public.hideMenu"
            class="hidden lg:block"
            aria-label="Menu"
            @click="openMenuMain"
          >
            <Menu />
          </button>
        </div>

        <!-- Mobile > Menu & Search  -->
        <button
          v-if="showMenuButton && !config.public.hideMenu && !config.public.hideSearch"
          class="ml-3.5 lg:hidden"
          aria-label="Menu"
          @click="openMenuMain"
        >
          <img src="@/assets/icons/menu.svg" alt="menu" width="39" height="39" />
        </button>

        <!-- Mobile > Menu only  -->
        <button
          v-if="showMenuButton && !config.public.hideMenu && config.public.hideSearch"
          class="ml-3.5 lg:hidden"
          aria-label="Menu"
          @click="openMenuMain"
        >
          <Menu />
        </button>

        <!-- Mobile > Search only -->
        <button
          v-if="showMenuButton && config.public.hideMenu && !config.public.hideSearch"
          class="ml-3.5 lg:hidden"
          aria-label="Search"
          @click="openSearch"
        >
          <Search />
        </button>
      </div>
    </Container>
  </header>
</template>

<script setup>
import { useRouter } from '#app'
import { Logo, LogoEn, LogoUk, Button, Container } from 'refresh-ui'
import Search from '@/components/atoms/Icons/Search.vue'
import Menu from '@/components/atoms/Icons/Menu.vue'
import { useMenu } from 'refresh-common'
import { useRuntimeConfig } from '#app'

const config = useRuntimeConfig()

const props = defineProps({
  showMenuButton: { type: Boolean, default: true },
  data: {
    type: Object,
    default: () => ({
      link_support_us: '10579',
      link_donate: '10580',
      link_membership: '10648',
    }),
  },
})

const router = useRouter()
const { openMenuMain } = useMenu()
const lang = useI18n().locale.value

// TODO: Get these urls from env or dashboard
const openSearch = () => {
  router.push({ path: '/search' })
}

// TODO: Make this url alias hack unneccesary
const aliasLookup = {
  10648: '/stod-oss/bli-medlem',
  10580: '/stod-oss/ge-gava',
  10579: '/stod-oss',
}

const defaultUrls = {
  en: {
    support: '',
    donate: 'https://act.project1882.org/1882/donation',
    membership: 'https://act.project1882.org/1882/monthly', // actually monthly donation, not membership
  },
  sv: {
    support: '/stod-oss',
    donate: '/stod-oss/ge-gava',
    membership: '/stod-oss/bli-medlem',
  },
  uk: {
    support: '',
    donate: 'https://act.project1882.org/1882/donation',
    membership: 'https://act.project1882.org/1882/monthly', // actually monthly donation, not membership
  },
  es: {
    support: '',
    donate: 'https://act.project1882.org/1882/donation?lang=es',
    membership: 'https://act.project1882.org/1882/monthly?lang=es', // actually monthly donation, not membership
  },
}

// TODO: Make this multilingual hack unneccesary
const langConf = computed(() => {
  if (lang === 'en') {
    return {
      show: { donate: true, membership: false, support: false, onlyDonate: true },
    }
  } else if (lang === 'sv') {
    return {
      show: { donate: true, membership: true, support: true },
    }
  } else if (lang === 'uk') {
    return {
      show: { donate: true, membership: false, support: false, onlyDonate: true },
    }
  } else if (lang === 'es') {
    return {
      show: { donate: true, membership: false, support: false, onlyDonate: true },
    }
  } else {
    return {
      show: { donate: true, membership: true, support: true },
    }
  }
})

const donateUrl = computed(() => {
  // console.log('props.data.link_donate', props.data.link_donate)
  const lookupUrl = aliasLookup[props.data.link_donate]
  const nodeUrl = props?.data?.link_donate ? '/node/' + props.data.link_donate : ''
  // console.log('lookupUrl', lookupUrl)
  // console.log('nodeUrl', nodeUrl)
  return lookupUrl || nodeUrl || defaultUrls[lang].donate
})

const membershipUrl = () => {
  const lookupUrl = aliasLookup[props.data.link_membership]
  const nodeUrl = props?.data?.link_membership ? '/node/' + props.data.link_membership : ''
  return lookupUrl || nodeUrl || defaultUrls[lang].membership
}

const supportUrl = () => {
  const lookupUrl = aliasLookup[props.data.link_support_us]
  const nodeUrl = props?.data?.link_support_us ? '/node/' + props.data.link_support_us : ''
  return lookupUrl || nodeUrl || defaultUrls[lang].support
}

const openDonate = async () => {
  await urlGoTo(donateUrl.value)
}

const openMember = async () => {
  await urlGoTo(membershipUrl())
}

const openSupport = async () => {
  await urlGoTo(supportUrl())
}

const urlGoTo = async url => {
  if (url.includes('http')) {
    await navigateTo(url, { external: true })
  } else {
    router.push({
      path: url,
    })
  }
}
</script>
