<template>
  <div class="mx-auto !max-w-[1292px]">
    <h4
      class="lg: mb-5 px-5 pt-10 pt-20 font-serif text-xl text-black lg:mb-10 lg:text-2xl"
      v-if="adjustedList.length > 0"
    >
      {{ isProtest ? $t('petitionForm.theLastProtests') : $t('petitionForm.theLastPetitions') }}
    </h4>
    <div
      class="grid grid-flow-row-dense grid-rows-3 gap-4 lg:grid-cols-3"
      v-if="adjustedList.length > 0"
    >
      <template v-for="comment in adjustedList" :key="comment.id">
        <div
          v-if="comment.message"
          class="max-w-xl rounded-2xl bg-white px-10 py-4 shadow-lg transition duration-500 hover:shadow-2xl"
        >
          <div class="mt-4">
            <p class="text-md mt-4 text-gray-600">{{ comment.message }}</p>
            <div class="mt-2 py-6 text-sm font-semibold">- {{ comment.name }}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref as dbRef, query, limitToLast } from 'firebase/database'
import { useDatabaseList } from 'vuefire'

interface Comment {
  date: string
  firstname: string
  lastname: string
  message: string
  name: string
}

const props = defineProps({
  isProtest: { type: Boolean, required: false, default: false },
  formId: {
    type: String,
    required: true,
  },
})

const length = 9

const adjustedList = computed(() => {
  const withMessage = comments.value.filter(comment => comment.message)
  const limitedList = withMessage.slice(0, length)
  return limitedList
})

const db = useDatabase()
const cRef = dbRef(db, 'comments/' + props.formId)
const comments = useDatabaseList<Comment>(query(cRef, limitToLast(150)))
</script>
