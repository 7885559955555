import deepClone from '../../utils/deepClone'
import { getRelationships } from './getRelationships'

export type getCategoryReturnType =
  | {
      title: string
      path: string
    }
  | undefined

export const getCategory = (relationships: any, included: any): getCategoryReturnType => {
  if (
    relationships?.field_taxonomy_term_cat?.data &&
    relationships?.field_taxonomy_term_cat?.data.length > 0
  ) {
    const copy = deepClone(relationships?.field_taxonomy_term_cat?.data)
    const id = Array.isArray(copy) && copy.length > 0 ? copy[0].id : copy?.id

    if (copy.length === 0) return

    const category = getRelationships(id, included)

    return {
      title: category?.attributes?.title,
      path:
        category?.attributes?.path?.alias || '/node/' + category?.attributes?.drupal_internal__nid,
    }
  }

  if (relationships?.field_tags?.data && relationships?.field_tags?.data.length > 0) {
    const copy = deepClone(relationships?.field_tags?.data)

    const id = Array.isArray(copy) && copy.length > 0 ? copy[0].id : copy?.id

    if (copy.length === 0) return

    const category = getRelationships(id, included)

    return {
      title: category?.attributes?.name,
      path: category?.attributes?.path?.alias,
    }
  }
}
