<template>
  <div :class="[!isShow ? 'mb-3.5 overflow-hidden ' : 'mb-3.5 ']">
    <div class="lg:rounded-2xl' border-grey-light rounded-xl border bg-white">
      <button
        class="flex w-full items-center justify-between p-4 lg:px-6 lg:py-5"
        @click="openClose"
      >
        <span
          class="w-full text-left font-serif text-2xl leading-[1.136] text-black lg:text-3xl lg:font-bold lg:leading-snug"
        >
          <slot name="header"></slot>
        </span>
        <PlusBox v-if="!isShow" class="ml-3 h-4 w-4 shrink-0 lg:h-6 lg:w-6" />
        <MinusBox v-else class="ml-3 h-4 w-4 shrink-0 lg:h-6 lg:w-6" />
      </button>
    </div>
    <div
      class="flex flex-col transition-all duration-700 ease-in-out"
      :style="heightBlock"
    >
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, type CSSProperties } from 'vue'
import PlusBox from '../atoms/Icons/PlusBox.vue'
import MinusBox from '../atoms/Icons/MinusBox.vue'

type HeightBlockStyle = CSSProperties & {
  maxHeight: string
}

const props = defineProps({
  open: { type: Boolean, default: false },
})

const isShow = ref(props.open)

const openClose = () => {
  isShow.value = !isShow.value
}

const heightBlock = computed<HeightBlockStyle>(() => {
  return isShow.value ? { maxHeight: '4000px' } : { maxHeight: '0' }
})
</script>
