import { ref } from 'vue'

export const useRecaptcha = (config: { cmsBasePath: string; recaptchaSiteKey: string }) => {
  const recaptchaAdded = ref(false)

  const addRecatcha = async () => {
    if (recaptchaAdded.value) return
    const script = document.createElement('script')

    script.src = 'https://www.google.com/recaptcha/api.js?render=' + config.recaptchaSiteKey
    script.async = true
    document.getElementsByTagName('body')[0].appendChild(script)
    recaptchaAdded.value = true
  }

  const recaptcha = async () => {
    //@ts-ignore
    const token = await window.grecaptcha.execute(config.recaptchaSiteKey, {
      action: 'submit',
    })

    const response = await fetch('/api/siteverify', {
      method: 'POST',
      body: JSON.stringify({
        token,
      }),
    })
    const data = await response.json()
    return data.success
  }

  return {
    addRecatcha,
    recaptcha,
  }
}
