<template>
  <svg
    width="38"
    height="15"
    viewBox="0 0 38 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37.7071 8.20711C38.0976 7.81658 38.0976 7.18342 37.7071 6.79289L31.3431 0.428932C30.9526 0.0384078 30.3195 0.0384078 29.9289 0.428932C29.5384 0.819457 29.5384 1.45262 29.9289 1.84315L35.5858 7.5L29.9289 13.1569C29.5384 13.5474 29.5384 14.1805 29.9289 14.5711C30.3195 14.9616 30.9526 14.9616 31.3431 14.5711L37.7071 8.20711ZM0 8.5H37V6.5H0V8.5Z"
      fill="currentColor"
    />
  </svg>
</template>

