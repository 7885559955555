<template>
  <div
    class="absolute bottom-3 left-0 right-0 z-10 mx-auto flex w-[244px] flex-row items-center justify-between"
  >
    <div class="cursor-pointer" @click="slidePrev()">
      <SliderArrowLeft />
    </div>
    <div class="cursor-pointer" @click="slideNext()">
      <SliderArrowRight />
    </div>
  </div>
</template>

<script setup lang="ts">
import SliderArrowLeft from '../atoms/Icons/SliderArrowLeft.vue'
import SliderArrowRight from '../atoms/Icons/SliderArrowRight.vue'

const props = defineProps({
  slider: Object,
})

const slideNext = () => {
  props.slider?.slideNext(500)
}
const slidePrev = () => {
  props.slider?.slidePrev(500)
}
</script>
