import { buildUrl } from 'cloudinary-build-url'
import { STORAGE_TYPES } from '@cld-apis/utils'

export type proxyOriginalImageReturnType = { url: string }

export const proxyOriginalImage = ({
  url,
  cloudinaryOptions,
  config,
}: {
  url?: { url: string }
  cloudinaryOptions: object
  config: { cloudname: string; cmsBasePath: string }
}): proxyOriginalImageReturnType => {
  if (!url?.url) return { url: '' }
  const proxyUrl = buildUrl(url.url, {
    cloud: {
      cloudName: config.cloudname,
      storageType: STORAGE_TYPES.FETCH,
    },
    ...cloudinaryOptions,
  })
  return { url: proxyUrl }
}
