<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.7104 20.2875L18.0004 16.6075C19.4405 14.8119 20.1379 12.5328 19.9492 10.2388C19.7605 7.94476 18.7001 5.81025 16.9859 4.27411C15.2718 2.73797 13.0342 1.91697 10.7333 1.97993C8.43243 2.04289 6.24311 2.98502 4.61553 4.6126C2.98795 6.24018 2.04582 8.4295 1.98286 10.7304C1.9199 13.0313 2.7409 15.2688 4.27704 16.983C5.81318 18.6971 7.94769 19.7576 10.2417 19.9463C12.5357 20.135 14.8148 19.4376 16.6104 17.9975L20.2904 21.6775C20.3834 21.7712 20.494 21.8456 20.6158 21.8964C20.7377 21.9471 20.8684 21.9733 21.0004 21.9733C21.1324 21.9733 21.2631 21.9471 21.385 21.8964C21.5068 21.8456 21.6174 21.7712 21.7104 21.6775C21.8906 21.491 21.9914 21.2418 21.9914 20.9825C21.9914 20.7231 21.8906 20.4739 21.7104 20.2875ZM11.0004 17.9975C9.61592 17.9975 8.26255 17.5869 7.1114 16.8178C5.96026 16.0486 5.06305 14.9553 4.53324 13.6762C4.00342 12.3972 3.8648 10.9897 4.1349 9.63183C4.40499 8.27396 5.07168 7.02668 6.05065 6.04772C7.02961 5.06875 8.27689 4.40206 9.63476 4.13197C10.9926 3.86187 12.4001 4.00049 13.6792 4.53031C14.9583 5.06012 16.0515 5.95733 16.8207 7.10847C17.5899 8.25962 18.0004 9.61299 18.0004 10.9975C18.0004 12.854 17.2629 14.6345 15.9501 15.9472C14.6374 17.26 12.8569 17.9975 11.0004 17.9975Z"
      fill="currentColor"
    />
  </svg>
</template>
