export enum FORM_STATUS {
  OPEN = 'open',
  CLOSED = 'closed',
  SCHEDULED = 'scheduled',
}

export type FormStatus = `${FORM_STATUS}` //'open' | 'closed' | 'scheduled'

export type FormType = {
  id: string
  title?: string
  description?: string
  elements?: any
  confirmation: {
    title: string
    message: string
  }
  status: FormStatus
  form_close_message?: string
  form_open_message?: string
  open: string | null
  close: string | null
}
