import { useRuntimeConfig, useRouter, useFetch, createError } from '#app'
import { flagError } from 'error-handling'

// NOTE: This can't be moved to a composable since it depends on the useFetch performance optimizations
// TODO: Create a separate version for refresh-common

type RouteType = { path: string; query?: any; hash?: any; params?: any }

type ResponseResourceType = {
  resolved: any
  redirect: any
}

export const useGetResource = async (route: RouteType) => {
  const router = useRouter()
  const config = useRuntimeConfig()
  // console.log('router getResource', route);

  const { data, error } = await useFetch<ResponseResourceType>(
    '/api/content?route=' + route.path + (route.query?.preview ? '&preview=true' : ''),
  )

  if (error.value?.data) {
    flagError(error.value?.data, 'useJsonApi/useGetResource')
    throw createError({
      statusCode: 404,
      fatal: true,
    })
  }

  if (data?.value?.resolved) {
    // console.log('resolved', data.value.resolved);
  }

  if (data?.value?.redirect) {
    // console.log('redirect', data.value.redirect);
    const redirectData = data?.value?.redirect.find((item: any) => item.from === route.path)
    // console.log('redirect to', redirectData.to);

    // Local redirect
    if (!redirectData.to.includes('http')) {
      router.push({ path: redirectData.to, query: route.query, hash: route.hash })
    }

    // TODO: Check if this can be removed -- does not seem to be used -- but if that is the case, is there still a way to handle external http redirects? Test this
    // TODO: There must be a better way to do this
    // Defer external redirect
    return { redirect: redirectData.to }
  }

  if (!data.value) {
    throw createError({
      statusCode: 404,
      fatal: true,
    })
  }
  return data.value
}

export const useGetData = async () => {
  const config = useRuntimeConfig()
  const lang = config.public.siteLang ? config.public.siteLang : 'sv'
  const { data, error } = await useFetch<any>('/api/static')

  if (error.value?.data) {
    flagError(error.value?.data, 'useJsonApi/useGetData')
  }

  return {
    additionalData: data?.value[0]?.data[lang],
    footerData: data?.value[1]?.data[lang],
    primaryNav: data?.value[2],
  }
}
