import { reactive } from 'vue';

export const menuState = reactive({ menu: false });

export const useMenu = () => {
  const disableScroll = () => document.body.classList.add('stop-scrolling');

  const enableScroll = () => document.body.classList.remove('stop-scrolling');

  const openMenuMain = () => {
    menuState.menu = true;
    disableScroll();
  };

  const closeMenuMain = () => {
    menuState.menu = false;
    enableScroll();
  };

  return {
    openMenuMain,
    closeMenuMain,
  };
};
