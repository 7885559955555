<template>
  <div class="relative flex flex-col space-y-2">
    <div class="font-text font-sans text-black lg:text-lg">
      {{ title }}
      <span v-if="required">*</span>
    </div>
    <label class="flex items-end" :for="id">
      <input
        :value="value"
        :required="required"
        :disabled="disabled"
        class="h-[60px] w-full flex-1 items-center rounded-md rounded-r-none border border-r-0 border-blue-dark border-transparent bg-white p-3 leading-none outline-0 focus:border-blue-dark focus:border-transparent focus:ring-0 focus-visible:border-blue-dark disabled:border-[#ADBACB] disabled:border-[#ADBACB] disabled:bg-[#D8D8D8] disabled:bg-[#D8D8D8] lg:p-4 lg:text-xl"
        readonly
        @click="handleClick"
      />

      <Button
        :title="value ? $t('fileField.remove') : $t('fileField.selectFile')"
        class="h-[60px] rounded-md rounded-l-none !border-0 !py-0 px-6 disabled:border-[#ADBACB] disabled:bg-[#ADBACB] lg:px-10"
        type-button="button"
        :is-loading="isLoading"
        :disabled="disabled"
        @click="handleClick"
      />
    </label>

    <input
      :id="id"
      ref="inputRef"
      type="file"
      :name="id"
      :required="required"
      :accept="accept(type)"
      class="absolute bottom-0 opacity-0"
      @change="uploadFunction"
    />
    <div class="font-sans text-red-500">{{ message }}</div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { Button } from 'refresh-ui'
import { useForm } from 'refresh-common'
import { useRuntimeConfig } from '#app'
const { cmsBasePath } = useRuntimeConfig().public

const { uploadFile } = useForm({ cmsBasePath })

const message = ref('')
const isLoading = ref(false)
const inputValue = reactive({})
const value = ref('')
const inputRef = ref(null)

const props = defineProps({
  id: String,
  required: Boolean,
  title: String,
  formId: String,
  modelValue: Object,
  type: String,
  disabled: Boolean,
})
const emit = defineEmits(['update:modelValue'])

const accept = type => {
  if (type === 'webform_image_file') {
    return '.gif,.jpg,.png,.svg,.jpeg'
  }
  if (type === 'managed_file') {
    return '.gif, .jpg, .png, .bmp, .eps, .tif, .pict, .psd, .txt, .rtf, .html, .odf, .pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .xml, .avi, .mov, .mp3, .ogg, .wav, .bz2, .dmg, .gz, .jar, .rar, .sit, .svg, .tar, .zip'
  }
  if (type === 'webform_document_file') {
    return '.txt, .rtf, .pdf, .doc, .docx, .odt, .ppt, .pptx, .odp, .xls, .xlsx, .ods'
  }
}
const handleClick = () => {
  if (!value.value) {
    inputRef.value.click()
    return
  }
  inputRef.value.value = ''
  value.value = ''
  inputValue[props.id] = ''
  emit('update:modelValue', inputValue)
}

const uploadFunction = async event => {
  message.value = ''
  isLoading.value = true

  const file = event.target.files[0]

  if (!file.size) {
    return
  }

  if (file.size > 3.2e7) {
    message.value = 'your file is larger than 32mb'
    isLoading.value = false
    return
  }

  const data = await uploadFile({
    file,
    webformId: props.formId,
    fieldName: props.id,
  })

  if (!data?.filename?.[0]?.value) {
    message.value = 'Something went wrong with the upload. Please try again or contact support.'
    isLoading.value = false
    return
  }

  value.value = data.filename[0].value
  inputValue[props.id] = data.fid[0].value

  emit('update:modelValue', inputValue)
  isLoading.value = false
}
</script>
