<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="flex flex-col px-4 lg:flex-row lg:px-0">
    <div class="lg:hidden">
      <SubTitle class="text-blue">{{ data?.subtitle }}</SubTitle>
      <Title custom-style="mb-4 leading-tight lg:leading-tight">
        {{ data?.title }}
      </Title>
    </div>
    <div
      class="relative aspect-square w-full shrink-0 basis-[44%] overflow-hidden rounded-2xl lg:max-h-[850px] lg:max-w-[850px] lg:rounded-bl-none lg:rounded-tl-none"
    >
      <NuxtImg
        v-if="data?.image?.url"
        :src="data?.image?.url"
        :alt="data.image.alt"
        class="absolute h-full w-full object-cover object-center"
        loading="lazy"
        provider="cloudinary"
        fit="fill"
        width="850"
        height="850"
        sizes="xs:100vw sm:100vw md:100vw lg:850px xl:850px xxl:850px"
      />
    </div>
    <div class="2.5xl:pl-36 flex basis-full flex-col justify-center py-6 lg:pl-16 lg:pt-10 xl:pt-8">
      <div class="max-w-screen-sm">
        <div class="hidden lg:block">
          <SubTitle class="text-blue"> {{ data?.subtitle }} </SubTitle>
          <Title custom-style="leading-tight lg:leading-tight">
            {{ data?.title }}
          </Title>
        </div>

        <div
          class="prose lg:prose-lg mb-10 mt-3.5 space-y-2 font-sans leading-snug text-black lg:mt-8 lg:space-y-3 lg:text-base lg:leading-normal"
          v-html="data?.copy"
        ></div>
        <Link
          v-if="data?.link"
          :title="data.link.title"
          :link="data.link.url"
          :external="data.link?.type === 'ext'"
        />
        <div v-else class="lg:h-10"></div>
      </div>
      <div ref="container"></div>
      <div class="mt-9" :style="{ width: widthContainer + 'px' }">
        <TinySlider :cards="data?.cards" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, computed } from 'vue'
import { useElementSize, useWindowSize } from '@vueuse/core'
import NuxtImg from '../atoms/NuxtImg.vue'
import SubTitle from '../atoms/SubTitle.vue'
import Title from '../atoms/Title.vue'
import Link from '../atoms/Link.vue'
import TinySlider from '../molecules/TinySlider.vue'

import type { ILinkData, IImageData, ICardData } from '../../types'

export interface ITinySliderBlock {
  id: string
  title?: string
  subtitle?: string
  image?: IImageData
  link?: ILinkData
  copy?: string
  cards?: ICardData[]
}

interface Props {
  data: ITinySliderBlock
}

defineProps<Props>()

const container = ref(null)
const widthContainer = ref(0)

const { width: widthWindow } = useWindowSize()
const { width } = useElementSize(container)

onMounted(() => {
  setTimeout(() => {
    widthContainer.value = width.value
  }, 100)
})

const w = computed(() => widthWindow.value)

watch(w, () => {
  widthContainer.value = 0
  setTimeout(() => {
    widthContainer.value = width.value
  }, 100)
})
</script>
