<template>
  <Swiper
    class="pagination w-[145px]"
    :slides-per-view="5"
    :space-between="15"
    :centered-slides="true"
    :loop="true"
    :modules="[Virtual]"
    :virtual="true"
    @swiper="setSwiper"
    @slide-change="onSlideChange"
  >
    <SwiperSlide
      v-for="(i, index) in slidesArray"
      :key="index"
      v-slot="{ isActive, isPrev, isNext }"
      :virtual-index="index"
    >
      <div
        class="h-[21px] w-[21px] rounded-full transition-all duration-500"
        :class="[
          isActive && ' bg-blue-dark',
          (isNext || isPrev) && 'scale-75 bg-blue',
          (prevPrev === index || nextNext === index) && 'scale-50 bg-blue-light',
        ]"
      ></div>
    </SwiperSlide>
  </Swiper>
</template>

<script setup lang="ts">
import 'swiper/css'
import 'swiper/css/virtual'
import { ref, computed, watch, onBeforeMount } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Swiper as SwiperType } from 'swiper'
import { Virtual } from 'swiper/modules'

const props = defineProps({
  slider: Object,
  slides: { type: Number, default: 0 },
  activeIndex: { type: Number, default: 0 },
})

const slidesArray = ref<number[]>([])
const realIndex = ref(0)
const controlledSwiper = ref<SwiperType | undefined>()

const prevPrev = computed(() => {
  const i = realIndex.value - 2
  return i >= 0 ? i : slidesArray.value.length + i
})
const nextNext = computed(() => {
  const i = realIndex.value + 2
  return i >= slidesArray.value.length ? i - slidesArray.value.length : i
})

const setSwiper = (swiper: SwiperType) => {
  controlledSwiper.value = swiper
  realIndex.value = controlledSwiper?.value?.realIndex || 0
}

const onSlideChange = () => {
  realIndex.value = controlledSwiper?.value?.realIndex || 0
}

onBeforeMount(() => {
  slidesArray.value = Array(props.slides * 100).fill(1)
})

watch(
  () => props?.activeIndex,
  (prev, nextValue) => {
    let next = nextValue ?? 0
    prev > next ? controlledSwiper?.value?.slideNext(300) : controlledSwiper?.value?.slidePrev(300)
  },
  { immediate: true },
)
</script>

<style scoped>
.swiper {
  padding-bottom: 10px;
}
</style>
