<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="shape-rendering: auto"
    width="200px"
    height="200px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <g transform="translate(77,50)">
      <g transform="rotate(0)">
        <circle cx="0" cy="0" r="3" :fill="colors[color]" fill-opacity="1">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.9s"
            values="2 2;1 1"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
            values="1;0"
            begin="-0.9s"
          ></animate>
        </circle>
      </g>
    </g>
    <g transform="translate(71.84345884812359,65.87020181189678)">
      <g transform="rotate(36)">
        <circle cx="0" cy="0" r="3" :fill="colors[color]" fill-opacity="0.9">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.8s"
            values="2 2;1 1"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
            values="1;0"
            begin="-0.8s"
          ></animate>
        </circle>
      </g>
    </g>
    <g transform="translate(58.34345884812358,75.67852593996915)">
      <g transform="rotate(72)">
        <circle cx="0" cy="0" r="3" :fill="colors[color]" fill-opacity="0.8">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.7s"
            values="2 2;1 1"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
            values="1;0"
            begin="-0.7s"
          ></animate>
        </circle>
      </g>
    </g>
    <g transform="translate(41.656541151876425,75.67852593996915)">
      <g transform="rotate(108)">
        <circle cx="0" cy="0" r="3" :fill="colors[color]" fill-opacity="0.7">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.6s"
            values="2 2;1 1"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
            values="1;0"
            begin="-0.6s"
          ></animate>
        </circle>
      </g>
    </g>
    <g transform="translate(28.15654115187642,65.87020181189678)">
      <g transform="rotate(144)">
        <circle cx="0" cy="0" r="3" :fill="colors[color]" fill-opacity="0.6">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.5s"
            values="2 2;1 1"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
            values="1;0"
            begin="-0.5s"
          ></animate>
        </circle>
      </g>
    </g>
    <g transform="translate(23,50)">
      <g transform="rotate(180)">
        <circle cx="0" cy="0" r="3" :fill="colors[color]" fill-opacity="0.5">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.4s"
            values="2 2;1 1"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
            values="1;0"
            begin="-0.4s"
          ></animate>
        </circle>
      </g>
    </g>
    <g transform="translate(28.156541151876414,34.12979818810323)">
      <g transform="rotate(216)">
        <circle cx="0" cy="0" r="3" :fill="colors[color]" fill-opacity="0.4">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.3s"
            values="2 2;1 1"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
            values="1;0"
            begin="-0.3s"
          ></animate>
        </circle>
      </g>
    </g>
    <g transform="translate(41.65654115187642,24.321474060030855)">
      <g transform="rotate(252)">
        <circle cx="0" cy="0" r="3" :fill="colors[color]" fill-opacity="0.3">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.2s"
            values="2 2;1 1"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
            values="1;0"
            begin="-0.2s"
          ></animate>
        </circle>
      </g>
    </g>
    <g transform="translate(58.343458848123575,24.32147406003085)">
      <g transform="rotate(288)">
        <circle cx="0" cy="0" r="3" :fill="colors[color]" fill-opacity="0.2">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.1s"
            values="2 2;1 1"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
            values="1;0"
            begin="-0.1s"
          ></animate>
        </circle>
      </g>
    </g>
    <g transform="translate(71.84345884812357,34.12979818810322)">
      <g transform="rotate(324)">
        <circle cx="0" cy="0" r="3" :fill="colors[color]" fill-opacity="0.1">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="0s"
            values="2 2;1 1"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
            values="1;0"
            begin="0s"
          ></animate>
        </circle>
      </g>
    </g>
  </svg>
</template>

<script setup lang="ts">
defineProps({
  color: {
    type: String,
    default: 'black',
  },
})

const colors = {
  black: '#000000',
  white: '#ffffff',
}
</script>
