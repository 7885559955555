<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="relative">
    <div class="container mx-auto px-4 lg:container" ref="containerRef"></div>
    <Container class="mb-11 text-center xl:mb-9">
      <SubTitle class="text-blue">{{ data?.subtitle }}</SubTitle>
      <Title>{{ data?.title }}</Title>
    </Container>
    <ClientOnly>
      <Swiper
        v-if="data?.cards && data?.cards.length"
        :modules="[Controller, Navigation, Pagination]"
        :controller="{ control: controlledSwiper }"
        :slides-per-view="'auto'"
        :space-between="30"
        :centered-slides="true"
        :loop="false"
        :breakpoints="breakpoints"
        :pagination="pagination"
        :initial-slide="data?.cards.length - 1"
        class="timeline relative"
        @swiper="setSwiper"
        @slide-change="onSlideChange"
        @slider-move="startMove"
        @transition-start="startMove"
        @transition-end="stopMove"
        @slide-change-transition-end="stopMove"
      >
        <div
          class="absolute bottom-0 left-0 top-0 z-30 w-[10%] bg-gradient-to-r group-[.is-blue]:from-blue-lighter group-[.is-white]:from-white"
        ></div>
        <SwiperSlide
          v-for="(item, index) in data?.cards"
          v-slot="{ isActive, isPrev, isNext }"
          :key="index"
          class="pb-0 pt-32 first:before:w-full xl:!w-[400px] 2xl:!w-[650px] 2xl:pb-16"
        >
          <div class="flex w-full flex-col items-center justify-center xl:h-[400px] 2xl:h-[650px]">
            <div
              class="absolute top-0 flex h-24 w-full items-start justify-center transition-transform delay-100 duration-500"
              :class="[
                isActive ? 'xl:aspect-square xl:w-[650px]' : ' lg:w-[380px]',
                isNext && 'translate-x-full xl:translate-x-[200%]',
              ]"
              :style="blockStyle({ isActive, isNext, isPrev, index })"
            >
              <div
                class="max-w-[277px] cursor-default text-center font-bold"
                :class="[
                  isActive
                    ? 'text-base font-bold text-blue lg:text-xl'
                    : 'text-sm font-normal text-black lg:text-lg',
                ]"
              >
                {{ item.timeline }}
              </div>
              <div
                class="absolute bottom-0 h-px -translate-y-4 bg-blue-line"
                :class="[
                  index === data?.cards.length - 1 ? 'right-[50%] w-[200%]' : 'left-[50%] w-[150%]',
                  index === 0 && 'left-[50%]',
                  index === data?.cards.length - 2 && !isActive && '!w-full',
                ]"
              ></div>
              <div
                class="absolute bottom-0 left-1/2 ml-4 inline-block w-16 -translate-x-1/2 transform overflow-hidden"
              >
                <div
                  class="h-4 w-4 origin-top-left -rotate-45 transform"
                  :class="[isActive ? 'bg-blue' : 'bg-blue-line']"
                ></div>
              </div>
            </div>

            <div
              class="relative flex aspect-[0.68] h-full w-full items-center overflow-hidden rounded-2xl transition-all delay-100 duration-500"
              :class="[
                isActive
                  ? ' xl:aspect-square xl:w-[400px] 2xl:w-[650px]'
                  : ' xl:h-[350px] xl:w-[260px] 2xl:h-[550px] 2xl:w-[380px]',
                isNext && 'translate-x-full xl:translate-x-[200%]',
                !isActive && 'scale-[0.85] xl:scale-100',
                nextNext === index && 'translate-x-[400%] opacity-0',
              ]"
              :style="blockStyle({ isActive, isNext, isPrev, index })"
            >
              <NuxtImg
                v-if="item?.image?.url"
                :src="item.image.url"
                :alt="item.image.alt"
                class="absolute h-full w-full object-cover"
                loading="lazy"
                provider="cloudinary"
                fit="fill"
                width="650"
                height="650"
                sizes="xs:100vw sm:100vw md:600px lg:650px xl:650px xxl:650px"
              />
              <div
                class="absolute bottom-0 h-2/4 w-full rounded-2xl bg-gradient-to-t from-blue-darker via-blue-dark"
              ></div>
              <div class="absolute bottom-0 w-full p-4 text-white transition-all lg:p-7">
                <h3 class="mb-2 font-serif text-2xl leading-none 2xl:text-3xl">
                  {{ item.title }}
                </h3>
                <div class="mb-4 font-sans text-base leading-snug 2xl:text-lg">
                  <span
                    :class="[isActive ? 'line-clamp-4' : 'line-clamp-3']"
                    v-html="item.copy"
                  ></span>
                </div>
                <LinkSecondary
                  v-if="item.link"
                  :title="item.link.title"
                  :link="item.link.url"
                  :external="item.link?.type === 'ext'"
                />
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      <SliderNavigation
        v-if="data?.cards?.length"
        :slider="controlledSwiper"
        :class="[data?.cards?.length <= 3 && 'xl:hidden']"
      />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { ref, computed, watch } from 'vue'
import type { Ref } from 'vue'
import type { Swiper as SwiperType } from 'swiper'
import type { SwiperOptions } from 'swiper/types'
import { Controller, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { useElementSize, useWindowSize } from '@vueuse/core'

import Container from '../atoms/Container.vue'
import LinkSecondary from '../atoms/LinkSecondary.vue'
import SubTitle from '../atoms/SubTitle.vue'
import Title from '../atoms/Title.vue'
import SliderNavigation from '../atoms/SliderNavigation.vue'
import NuxtImg from '../atoms/NuxtImg.vue'

import type { ICardData } from '../../types'

interface ITimelineSliderBlockCard extends ICardData {
  date?: string
  position?: string
  name?: string
  timeline?: string
}

export interface ITimelineSliderBlock {
  id: string
  title?: string
  subtitle?: string
  cards?: ITimelineSliderBlockCard[]
}

interface Props {
  data: ITimelineSliderBlock
}

const props = defineProps<Props>()

const { width } = useWindowSize()

const containerRef = ref(null)
const controlledSwiper: Ref<SwiperType | undefined> = ref()
const realIndex = ref(0)
const showShadow = ref(true)

const { width: widthContainer } = useElementSize(containerRef)

const length = computed(() => (props.data?.cards ? props.data?.cards.length : 0))

const pagination = computed(() => ({
  clickable: true,
  dynamicBullets: true,
  clickableClass: length.value <= 3 ? 'xl:hidden z-10' : 'z-10',
}))

const breakpoints: SwiperOptions['breakpoints'] = {
  320: {
    slidesPerView: 1.3,
    spaceBetween: 0,
  },
  768: {
    slidesPerView: 2.5,
    spaceBetween: 50,
  },

  1280: {
    slidesPerView: 'auto',
    spaceBetween: 30,
  },
}

const widthScreen = computed(() => width.value)

watch(widthScreen, () => {
  controlledSwiper.value?.update()
})

const nextNext = computed(() => {
  const i = realIndex.value + 2
  return i >= length.value ? -1 : i
})

const prevPrev = computed(() => {
  const i = realIndex.value - 2
  return i >= 0 ? i : length.value + i
})

const lastSlide = computed(() => {
  const i = realIndex.value - 3
  return i >= 0 ? i : length.value + i
})

const lastSlide2 = computed(() => {
  const i = realIndex.value - 4
  return i >= 0 ? i : length.value + i
})

const setSwiper = (swiper: SwiperType) => {
  controlledSwiper.value = swiper
  realIndex.value = controlledSwiper?.value?.realIndex
}

const onSlideChange = () => {
  realIndex.value = controlledSwiper?.value?.realIndex || 0
}

const startMove = () => {
  showShadow.value = false
}
const stopMove = () => {
  showShadow.value = true
}

const blockStyle = ({
  isActive,
  isPrev,
  isNext,
  index,
}: {
  isActive: boolean
  isPrev: boolean
  isNext: boolean
  index: number
}) => {
  const isDesktop = widthContainer.value && width.value >= 1280 && width.value < 1536
  const isExtraDesktop = widthContainer.value && width.value >= 1536
  if (isActive && isDesktop) {
    return { marginLeft: widthContainer.value / 2 + 260 + 'px' }
  }

  if (isActive && isExtraDesktop) {
    return { marginLeft: widthContainer.value / 2 + 130 + 'px' }
  }

  if (isPrev && isDesktop) {
    return { marginLeft: widthContainer.value / 2 + 360 + 'px' }
  }

  if (isPrev && isExtraDesktop) {
    return { marginLeft: widthContainer.value / 2 + 350 + 'px' }
  }

  if (prevPrev.value === index && isDesktop) {
    return { marginLeft: widthContainer.value / 2 + 580 + 'px' }
  }

  if (prevPrev.value === index && isExtraDesktop) {
    return { marginLeft: widthContainer.value / 2 + 860 + 'px' }
  }
  if (lastSlide.value === index && isDesktop) {
    return { marginLeft: widthContainer.value / 2 + 800 + 'px' }
  }

  if (lastSlide.value === index && isExtraDesktop) {
    return { marginLeft: widthContainer.value / 2 + 1360 + 'px' }
  }
  if (lastSlide2.value === index && isDesktop) {
    return { marginLeft: widthContainer.value / 2 + 1000 + 'px' }
  }

  if (lastSlide2.value === index && isExtraDesktop) {
    return { marginLeft: widthContainer.value / 2 + 1860 + 'px' }
  }
  if (isNext) {
    return { marginLeft: widthContainer.value / 2 + 100 + 'px' }
  }
}
</script>

<style>
.timeline .swiper-wrapper {
  align-items: center;
  position: relative;
}
.timeline .swiper-slide-next + .swiper-slide {
  opacity: 0;
}
</style>
