<template>
  <svg
    width="38"
    height="27"
    viewBox="0 0 38 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1 14.7C12.5 15.0333 13.6 15.7333 14.4 16.8C15.2667 17.8667 15.7 19.0667 15.7 20.4C15.7 22.2 15.0667 23.7 13.8 24.9C12.6 26.1 10.9667 26.7 8.9 26.7C6.3 26.7 4.16667 25.8333 2.5 24.1C0.9 22.3 0.1 19.9 0.1 16.9C0.1 13.9 0.666667 11.1333 1.8 8.59999C2.93333 6 4.66667 3.43333 7 0.899999L14.8 5.3C13.4667 7.03333 12.4333 8.53333 11.7 9.8C10.9667 11.0667 10.6 12.1667 10.6 13.1C10.6 13.8333 10.7667 14.3667 11.1 14.7ZM26.0773 12.8C24.6773 12.4667 23.544 11.7667 22.6773 10.7C21.8773 9.63333 21.4773 8.43333 21.4773 7.1C21.4773 5.23333 22.0773 3.73333 23.2773 2.59999C24.544 1.46666 26.244 0.899999 28.3773 0.899999C30.9107 0.899999 33.0107 1.8 34.6773 3.6C36.344 5.33333 37.1773 7.7 37.1773 10.7C37.1773 16.3667 34.9107 21.7 30.3773 26.7L22.4773 22.2C25.2107 18.8667 26.5773 16.3 26.5773 14.5C26.5773 13.7667 26.4107 13.2 26.0773 12.8Z"
      fill="#00B4D2"
    />
  </svg>
</template>
