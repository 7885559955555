<template>
  <svg
    width="154"
    height="63"
    viewBox="0 0 154 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Djurens Rätt</title>
    <g clip-path="url(#clip0_4125_30250)">
      <path
        d="M0 35.811H8.0389C13.396 35.811 18.4075 39.0799 18.4075 45.968C18.4075 52.8561 12.5064 56.1186 7.60367 56.1186H0V35.811ZM6.33639 52.8498C10.8935 52.8498 14.6057 50.8999 14.6057 45.968C14.6057 41.0361 11.4055 39.0799 6.92523 39.0799H3.62903V52.8498H6.33639Z"
        fill="#00B4D2"
      />
      <path
        d="M23.6232 42.355V56.9533C23.6232 60.3878 22.9 63.0003 18.9573 63.0003C18.3226 63.0146 17.6897 62.9286 17.082 62.7454L17.338 61.2034C17.8309 61.3181 20.167 61.5411 20.167 56.9788V42.3232L23.6232 42.355ZM21.8631 35.6071C22.2696 35.627 22.6612 35.7652 22.9896 36.0045C23.3179 36.2439 23.5685 36.5738 23.7104 36.9536C23.8522 37.3333 23.8791 37.7461 23.7876 38.1409C23.6962 38.5357 23.4904 38.895 23.1958 39.1746C22.9013 39.4541 22.5308 39.6414 22.1303 39.7134C21.7299 39.7854 21.317 39.7389 20.9428 39.5796C20.5687 39.4203 20.2496 39.1552 20.0253 38.8172C19.8009 38.4792 19.6811 38.0831 19.6806 37.678C19.6872 37.3993 19.749 37.1247 19.8623 36.8697C19.9756 36.6148 20.1382 36.3846 20.3409 36.1923C20.5436 36 20.7823 35.8493 21.0435 35.7489C21.3047 35.6485 21.5832 35.6003 21.8631 35.6071Z"
        fill="#00B4D2"
      />
      <path
        d="M38.7753 56.1185H35.4919V53.9138H35.4343C35.0748 54.7191 34.4758 55.3953 33.7181 55.8511C32.9605 56.3069 32.0806 56.5203 31.1973 56.4626C27.485 56.4626 26.1602 53.8501 26.1602 51.04V42.355H29.6164V49.3196C29.6164 50.8425 29.7316 53.3658 32.1765 53.3658C34.4487 53.3658 35.3767 51.7027 35.3767 49.8612V42.355H38.8329L38.7753 56.1185Z"
        fill="#00B4D2"
      />
      <path
        d="M41.25 42.3548H44.7062V44.534H44.7638C45.1231 43.7595 45.7012 43.106 46.4275 42.6533C47.1539 42.2007 47.9969 41.9685 48.8537 41.9852C49.2748 41.9926 49.6928 42.0591 50.0953 42.1827V45.5089C49.551 45.3557 48.9902 45.268 48.4248 45.2477C47.947 45.2152 47.4676 45.2813 47.0165 45.4417C46.5655 45.6021 46.1526 45.8535 45.8036 46.18C45.4545 46.5066 45.1769 46.9013 44.9881 47.3395C44.7993 47.7777 44.7033 48.25 44.7062 48.7268V56.1183H41.25V42.3548Z"
        fill="#00B4D2"
      />
      <path
        d="M53.7648 50.4409C53.8401 51.3592 54.2691 52.2129 54.9623 52.8233C55.6554 53.4338 56.5588 53.7537 57.4834 53.7161C58.2074 53.7213 58.9222 53.554 59.568 53.2281C60.2138 52.9022 60.7719 52.4272 61.1957 51.8427L63.679 53.7161C62.9849 54.5945 62.0949 55.2999 61.0796 55.7766C60.0642 56.2532 58.9514 56.4881 57.8291 56.4624C53.6496 56.4624 50.3086 53.5696 50.3086 49.2302C50.3086 44.8909 53.6496 42.0107 57.8291 42.0107C61.6693 42.0107 64.3063 44.7061 64.3063 49.4915V50.4409H53.7648ZM60.8565 47.8921C60.8052 45.8786 59.4804 44.6041 57.317 44.6041C55.2753 44.6041 54.008 45.8785 53.7456 47.873L60.8565 47.8921Z"
        fill="#00B4D2"
      />
      <path
        d="M65.5981 42.3551H68.8751V44.5662H68.9391C69.2975 43.7614 69.8953 43.0854 70.6519 42.6296C71.4085 42.1738 72.2873 41.9601 73.1698 42.0174C76.8884 42.0174 78.2133 44.6236 78.2133 47.4336V56.1187H74.7571V49.1541C74.7571 47.6312 74.6418 45.1078 72.1969 45.1078C69.9183 45.1078 68.9967 46.7709 68.9967 48.6061V56.1187H65.5469L65.5981 42.3551Z"
        fill="#00B4D2"
      />
      <path
        d="M88.0563 46.1654C87.7642 45.7212 87.3625 45.3591 86.8896 45.1136C86.4167 44.8682 85.8884 44.7476 85.3554 44.7635C84.4337 44.7635 83.3393 45.1904 83.3393 46.2291C83.3393 48.6887 90.7957 46.6815 90.7957 52.0403C90.7957 55.3156 87.6595 56.4625 84.8369 56.4625C82.7056 56.4625 80.8559 55.9145 79.4414 54.3406L81.752 52.1869C82.6416 53.1682 83.5697 53.8819 85.0354 53.8819C86.0466 53.8819 87.3459 53.3912 87.3459 52.3016C87.3459 49.4661 79.8766 51.7026 79.8766 46.4521C79.8766 43.3872 82.6416 41.9917 85.4386 41.9917C87.2819 41.9917 89.2404 42.5652 90.3733 44.0817L88.0563 46.1654Z"
        fill="#00B4D2"
      />
      <path
        d="M97.0352 35.811H104.12C108.044 35.811 111.724 37.0854 111.724 41.635C111.724 44.5662 110.028 46.7327 107.007 47.1405L112.422 56.1186H108.044L103.314 47.5164H100.664V56.1186H97.0352V35.811ZM103.487 44.4132C105.535 44.4132 107.922 44.2476 107.922 41.6032C107.922 39.2009 105.682 38.9078 103.807 38.9078H100.664V44.4132H103.487Z"
        fill="#00B4D2"
      />
      <path
        d="M122.07 54.2259H121.98C121.506 54.9548 120.847 55.5456 120.069 55.9385C119.291 56.3313 118.422 56.5121 117.551 56.4625C114.991 56.4625 112.508 55.0607 112.508 52.3335C112.508 47.8731 117.75 47.5417 121.212 47.5417H122.07V47.153C122.07 45.4517 120.745 44.6042 118.902 44.6042C117.535 44.5974 116.216 45.1049 115.209 46.0252L113.391 44.2155C114.173 43.4768 115.096 42.9011 116.105 42.5225C117.114 42.1439 118.189 41.9699 119.267 42.0108C125.168 42.0108 125.168 46.2482 125.168 48.2044V56.1184H122.057L122.07 54.2259ZM116.226 35.8109C116.631 35.8333 117.02 35.9731 117.345 36.2132C117.671 36.4532 117.919 36.7829 118.058 37.1616C118.198 37.5402 118.223 37.9513 118.13 38.3439C118.038 38.7365 117.832 39.0936 117.537 39.3709C117.243 39.6483 116.874 39.8339 116.475 39.9047C116.076 39.9755 115.666 39.9284 115.293 39.7694C114.921 39.6103 114.604 39.3461 114.381 39.0096C114.157 38.673 114.038 38.2787 114.038 37.8754C114.045 37.5967 114.108 37.3222 114.222 37.0676C114.336 36.813 114.499 36.5833 114.702 36.3916C114.906 36.1998 115.145 36.0499 115.406 35.9502C115.668 35.8506 115.947 35.8032 116.226 35.8109ZM121.872 49.9567H121.148C119.228 49.9567 115.964 50.0969 115.964 52.0722C115.964 53.3466 117.244 53.8819 118.383 53.8819C120.745 53.8819 121.872 52.6457 121.872 50.6959V49.9567ZM122.153 35.8109C122.558 35.832 122.948 35.9709 123.274 36.2102C123.601 36.4495 123.849 36.7789 123.99 37.1575C124.13 37.5361 124.156 37.9474 124.064 38.3405C123.972 38.7336 123.767 39.0912 123.473 39.3692C123.179 39.6472 122.81 39.8333 122.411 39.9046C122.012 39.9759 121.6 39.9292 121.228 39.7702C120.855 39.6113 120.537 39.3471 120.314 39.0103C120.091 38.6736 119.971 38.279 119.971 37.8754C119.977 37.597 120.039 37.3227 120.152 37.0681C120.266 36.8135 120.429 36.5837 120.631 36.3919C120.834 36.2001 121.073 36.0501 121.334 35.9503C121.595 35.8506 121.874 35.8032 122.153 35.8109Z"
        fill="#00B4D2"
      />
      <path
        d="M125.844 45.2735V42.3551H128.698V37.5952H132.155V42.387H135.956V45.3053H132.155V51.3333C132.155 52.7096 132.551 53.5698 134.075 53.5698C134.728 53.5984 135.376 53.4485 135.95 53.1365V56.0294C135.076 56.3612 134.145 56.5192 133.211 56.4946C129.556 56.4946 128.692 54.857 128.692 51.62V45.2735H125.844Z"
        fill="#00B4D2"
      />
      <path
        d="M135.613 45.2735V42.3551H138.468V37.5952H141.918V42.387H145.72V45.3053H141.918V51.3333C141.918 52.7096 142.321 53.5698 143.838 53.5698C144.489 53.5992 145.136 53.4492 145.707 53.1365V56.0294C144.835 56.3612 143.907 56.5192 142.974 56.4946C139.313 56.4946 138.455 54.857 138.455 51.62V45.2735H135.613Z"
        fill="#00B4D2"
      />
      <path
        d="M151.689 51.8746C152.284 51.8609 152.861 52.0825 153.293 52.4908C153.724 52.8992 153.976 53.4612 153.993 54.0538C154.017 54.5135 153.903 54.9698 153.664 55.3638C153.425 55.7578 153.072 56.0714 152.653 56.264C152.233 56.4567 151.765 56.5195 151.308 56.4444C150.852 56.3692 150.429 56.1596 150.094 55.8426C149.759 55.5256 149.527 55.1158 149.428 54.6662C149.33 54.2166 149.369 53.7478 149.54 53.3205C149.712 52.8931 150.009 52.5269 150.392 52.2692C150.775 52.0114 151.226 51.874 151.689 51.8746Z"
        fill="#00B4D2"
      />
      <path
        d="M91.1037 6.11736C89.2859 3.06517 83.7496 -1.91136 72.5937 2.4662C69.7647 3.56219 67.019 5.04686 64.0044 4.02734C61.3546 3.13526 56.9639 1.88635 54.4038 0.841343C50.4355 -0.732542 45.5648 -0.02525 43.4207 2.11574C42.115 3.45386 35.9258 9.94693 44.8032 20.1549C51.722 28.1836 68.4847 32.1789 72.7473 32.421C72.8688 32.4275 72.9906 32.4275 73.1122 32.421C74.0658 32.472 74.6226 32.4529 74.6226 32.4529C76.3955 32.4019 68.8623 28.8718 68.3759 28.1071C71.8321 27.6165 90.4124 25.278 92.3069 12.1771C92.6235 10.0794 92.1982 7.93737 91.1037 6.11736Z"
        fill="#00B4D2"
      />
    </g>
    <defs>
      <clipPath id="clip0_4125_30250">
        <rect width="154" height="63" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
