<template>
  <SwishSpinner
    v-if="showSpinner"
    :show-qr-code="showQrCode"
    :show-loader="showLoader"
    :qr="swishResponse.qr"
    :swish-app-url="swishResponse.swishAppUrl"
    :show-link="showLink"
    :show-retry="isError"
    @onRetry="handleRetry"
    @onClose="handleStop"
  />
  <form>
    <template v-if="isDesktop">
      <div v-if="showQrCode">
        <Input
          v-model="sum"
          :v$="v$.sum"
          type="number"
          :min="0"
          :label="$t('sum')"
          style-input="border !border-blue-dark focus:border-blue-dark shadow-[0_12px_33px_0_rgba(5, 58, 65, 0.04)] text-blue text-sm lg:text-base font-bold !pr-10 w-full"
          :currency="$t('currency')"
          class="mt-6"
          :right="otherRightString"
        />
        <Button
          :title="ERROR_STATUSES.includes(status) ? 'Retry' : 'Donate'"
          color="pink"
          class="mt-8 w-full"
          @click.prevent="handleFormMcommerce({ donationAmount: String(sum), qrCode: true })"
          :disabled="v$.sum.$invalid ? true : undefined"
        />
        <div class="mt-4 font-sans text-sm text-black [&_a]:underline">
          För att kunna hjälpa dig med din gåva och informera dig om vårt arbete för djuren sparar
          vi de uppgifter du lämnar. Det gör vi enligt dataskyddsförordningen vilket innebär att vi
          garanterar att dina uppgifter skyddas och inte lämnas ut till någon annan. Läs mer i vår
          <a href="/integritet" target="_blank">integritetspolicy</a>.
        </div>
      </div>
      <div v-else>
        <div class="mt-6 flex items-center gap-4">
          <Input
            v-model="sum"
            :v$="v$.sum"
            type="number"
            :min="0"
            :label="$t('sum')"
            style-input="border !border-blue-dark focus:border-blue-dark shadow-[0_12px_33px_0_rgba(5, 58, 65, 0.04)] text-blue text-sm lg:text-base font-bold !pr-10 w-full"
            :right="otherRightString"
          />
          <Input
            v-model="mobile"
            :v$="v$.mobile"
            :label="$t('mobile-number')"
            style-input="border !border-blue-dark focus:border-blue-dark shadow-[0_12px_33px_0_rgba(5, 58, 65, 0.04)] text-blue text-sm lg:text-base font-bold !pr-10 w-full"
          />
        </div>
        <Button
          :title="ERROR_STATUSES.includes(status) ? 'Retry' : 'Donate'"
          color="pink"
          class="mt-8 w-full"
          @click.prevent="handleFormEcommerce({ donationAmount: String(sum) })"
          :disabled="v$.sum.$invalid ? true : undefined"
        />
        <div class="mt-4 font-sans text-sm text-black [&_a]:underline">
          För att kunna hjälpa dig med din gåva och informera dig om vårt arbete för djuren sparar
          vi de uppgifter du lämnar. Det gör vi enligt dataskyddsförordningen vilket innebär att vi
          garanterar att dina uppgifter skyddas och inte lämnas ut till någon annan. Läs mer i vår
          <a href="/integritet" target="_blank">integritetspolicy</a>.
        </div>
      </div>
    </template>
    <template v-else>
      <Input
        v-model="sum"
        :v$="v$.sum"
        type="number"
        :min="0"
        :label="$t('sum')"
        style-input="border !border-blue-dark focus:border-blue-dark shadow-[0_12px_33px_0_rgba(5, 58, 65, 0.04)] text-blue text-sm lg:text-base font-bold !pr-10 w-full"
        :currency="$t('currency')"
        class="mt-6"
        :right="otherRightString"
      />
      <Button
        :title="ERROR_STATUSES.includes(status) ? 'Retry' : 'nästa'"
        color="pink"
        class="mt-8 w-full"
        @click.prevent="handleFormMcommerce({ donationAmount: String(sum) })"
        :disabled="v$.sum.$invalid ? true : undefined"
      />
      <div class="mt-8 font-sans text-sm text-black [&_a]:underline">
        För att kunna hjälpa dig med din gåva och informera dig om vårt arbete för djuren sparar vi
        de uppgifter du lämnar. Det gör vi enligt dataskyddsförordningen vilket innebär att vi
        garanterar att dina uppgifter skyddas och inte lämnas ut till någon annan. Läs mer i vår
        <a href="/integritet" target="_blank">integritetspolicy</a>.
      </div>
    </template>
  </form>
</template>

<script lang="ts" setup>
/* BigCtaFormComponent for Swish */
import { useI18n } from '#imports'
import { ref, computed } from 'vue'
import { Button, Input } from 'refresh-ui'
import { flagError } from 'error-handling'
import { useVuelidate } from '@vuelidate/core'
import { swedishMobile } from 'vuelidate-validators'
import { PAYMENT_STATUS, ERROR_STATUSES } from 'swish'
import { minValue, helpers, required } from '@vuelidate/validators'
import SwishSpinner from '~/components/molecules/SwishSpinner.vue'

const { $rollbar } = useNuxtApp()
const { t } = useI18n()

const { isDesktop } = useDevice()

const sum = ref('200')
const minSum = ref('10')
const mobile = ref('')

const status = ref<any>(null)

const showSpinner = ref(false)
const showLoader = ref(true)
const showLink = ref(false)
const isError = ref(false)

// switch on the desktop to use qrcode or not.
const showQrCode = ref(true)

const swishResponseInit = {
  operationId: null,
  qr: '',
  swishAppUrl: '',
  message: '',
}

const swishResponse = ref(swishResponseInit)

type localRulesType = { [key: string]: any }
const rules = computed(() => {
  const localRules: localRulesType = {
    sum: {
      minValue: helpers.withMessage(
        t('sum.min-sum.error', { sum: minSum.value, currency: t('currency') }),
        minValue(minSum.value),
      ),
    },
  }
  if (!showQrCode.value && isDesktop) {
    localRules['mobile'] = {
      swedishMobile: helpers.withMessage(t('mobile-number.swedish.error'), swedishMobile),
      required: helpers.withMessage(t('mobile-number.swedish.error'), required),
    }
  }

  return localRules
})

const v$ = useVuelidate(rules, { sum, mobile })

const otherRightString = `<span class='absolute right-5 top-3 lg:top-4 text-sm font-bold text-blue lg:text-lg'>${t(
  'currency',
)}</span>`

interface IDonationRequestBody {
  amount: string
  pageSuccess: string
  pageRejected: string
  payeePaymentReference: string
}

const createPaymentPoller = () => {
  let stopPolling = false

  const pollCheckPayment = async (operationId: string) => {
    const interval = 2000 // Interval in milliseconds (e.g., 5000ms = 5 seconds)

    while (!stopPolling) {
      try {
        const response = await $fetch(`/api/swish?id=${operationId}`)
        status.value = response.status

        if (response.status === PAYMENT_STATUS.PAID) {
          console.log('Payment status is paid!')
          swishResponse.value = swishResponseInit
          showSpinner.value = false
          await navigateTo('/swish/success')
          break
        }

        if (ERROR_STATUSES.includes(response.status)) {
          console.log('Payment status:', response.status)
          swishResponse.value = swishResponseInit
          isError.value = true
          showLoader.value = false
          showLink.value = false
          break
        }

        if (response.status !== PAYMENT_STATUS.CREATED) {
          swishResponse.value = swishResponseInit
          showLoader.value = true
        }

        console.log('Payment status:', response.status)
      } catch (error) {
        console.error('Error occurred:', error)
        break
      }

      await new Promise(resolve => setTimeout(resolve, interval))
    }
  }

  const stopPollingFn = () => {
    stopPolling = true
  }

  return { pollCheckPayment, stopPolling: stopPollingFn }
}

const { pollCheckPayment, stopPolling } = createPaymentPoller()

const handleFormMcommerce = async ({
  donationAmount,
  qrCode = false,
}: {
  donationAmount: string
  qrCode?: boolean
}) => {
  isError.value = false
  v$.value.$validate()
  if (v$.value.$invalid) return false
  try {
    showSpinner.value = true
    swishResponse.value = swishResponseInit

    if (!qrCode) {
      showLink.value = true
      showQrCode.value = false
    }

    showLoader.value = false

    const requestBody: IDonationRequestBody = {
      amount: donationAmount,
      pageSuccess: '/swish/success',
      pageRejected: '/swish/reject',
      payeePaymentReference: '123',
    }

    const response = await $fetch('/api/swish', {
      method: 'POST',
      body: JSON.stringify(requestBody),
    })
    pollCheckPayment(response.operationId)

    swishResponse.value = response
  } catch (error) {
    //@ts-ignore
    flagError(error, 'swish/handleFormMcommerce', $rollbar)
    console.log(error)
  }
}

const handleFormEcommerce = async ({ donationAmount }: { donationAmount: string }) => {
  try {
    showLoader.value = true
    isError.value = false
    v$.value.$validate()

    if (v$.value.$invalid) return false

    showSpinner.value = true
    swishResponse.value = swishResponseInit
    const mobileNumber = mobile.value.startsWith('+') ? mobile.value.slice(1) : mobile.value
    const response = await $fetch('/api/swish', {
      method: 'POST',
      body: {
        amount: donationAmount,
        payerAlias: mobileNumber,
        payeePaymentReference: '123',
      },
    })

    swishResponse.value = response
    if (!response.errors) {
      pollCheckPayment(response.operationId)
    }
  } catch (error) {
    //@ts-ignore
    flagError(error, 'swish/handleFormEcommerce', $rollbar)
    console.log(error)
  }
}

const handleRetry = async () => {
  stopPolling()
  if (isDesktop && showQrCode.value) {
    handleFormMcommerce({ donationAmount: String(sum.value), qrCode: showQrCode.value })
    return
  }

  if (isDesktop) {
    handleFormEcommerce({ donationAmount: String(sum.value) })
    return
  }
  handleFormMcommerce({ donationAmount: String(sum.value) })
}

const handleStop = () => {
  stopPolling()
  showSpinner.value = false
}
</script>
