<template>
  <svg
    width="21"
    height="15"
    viewBox="0 0 21 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.2071 8.20711C20.5976 7.81658 20.5976 7.18342 20.2071 6.79289L13.8431 0.428933C13.4526 0.0384089 12.8195 0.0384088 12.4289 0.428933C12.0384 0.819458 12.0384 1.45262 12.4289 1.84315L18.0858 7.5L12.4289 13.1569C12.0384 13.5474 12.0384 14.1805 12.4289 14.5711C12.8195 14.9616 13.4526 14.9616 13.8431 14.5711L20.2071 8.20711ZM0.5 8.5L19.5 8.5L19.5 6.5L0.5 6.5L0.5 8.5Z"
      fill="white"
    />
  </svg>
</template>

<script setup></script>
