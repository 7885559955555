<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 13.5H15C15.2652 13.5 15.5196 13.3946 15.7071 13.2071C15.8946 13.0196 16 12.7652 16 12.5C16 12.2348 15.8946 11.9804 15.7071 11.7929C15.5196 11.6054 15.2652 11.5 15 11.5H9C8.73478 11.5 8.48043 11.6054 8.29289 11.7929C8.10536 11.9804 8 12.2348 8 12.5C8 12.7652 8.10536 13.0196 8.29289 13.2071C8.48043 13.3946 8.73478 13.5 9 13.5ZM21 2.5H3C2.73478 2.5 2.48043 2.60536 2.29289 2.79289C2.10536 2.98043 2 3.23478 2 3.5V21.5C2 21.7652 2.10536 22.0196 2.29289 22.2071C2.48043 22.3946 2.73478 22.5 3 22.5H21C21.2652 22.5 21.5196 22.3946 21.7071 22.2071C21.8946 22.0196 22 21.7652 22 21.5V3.5C22 3.23478 21.8946 2.98043 21.7071 2.79289C21.5196 2.60536 21.2652 2.5 21 2.5ZM20 20.5H4V4.5H20V20.5Z"
      fill="#00B4D2"
    />
  </svg>
</template>
