<template>
  <div class="flex flex-col space-y-2">
    <div class="font-text font-sans text-black lg:text-lg">
      {{ title }}
      <span v-if="required">*</span>
    </div>
    <select
      :name="id + '[select]'"
      :hidden="hidden"
      :disabled="disabled"
      :required="required"
      :value="inputValue[id + '[select]']"
      class="max-h-[60px] w-full flex-1 rounded-md border border-blue-dark p-3 leading-none outline-0 focus:border-blue-dark focus:border-transparent focus:ring-0 focus-visible:border-blue-dark disabled:border-[#ADBACB] disabled:bg-[#D8D8D8] lg:p-4 lg:text-xl lg:leading-none"
      @change="inputValue[$event.target.name] = $event.target.value"
    >
      <option value>- {{ $t('select') }} -</option>
      <option
        v-for="item in [...Object.entries(options), ['_other_', otherLabel]]"
        :key="item"
        :value="item[0]"
      >
        {{ item[1] }}
      </option>
    </select>
    <input
      v-show="inputValue[id + '[select]'] === '_other_'"
      v-model="inputValue[id + '[other]']"
      type="text"
      :name="id + '[other]'"
      :required="inputValue[id + '[select]'] === '_other_' && required"
      :placeholder="otherPlaceholder || $t('enterOther')"
      class="!mt-4 max-h-[60px] w-full flex-1 rounded-md border border-blue-dark border-transparent p-3 leading-none outline-0 focus:border-blue-dark focus:border-transparent focus:ring-0 focus-visible:border-blue-dark lg:p-4 lg:text-xl lg:leading-none"
      @input="inputValue[$event.target.name] = $event.target.value"
    />
  </div>
</template>

<script setup>
import { reactive, watch } from 'vue'

const props = defineProps({
  id: String,
  title: String,
  name: String,
  modelValue: Object,
  options: Object,
  required: Boolean,
  otherLabel: String,
  otherPlaceholder: String,
  disabled: { type: Boolean, default: false },
  hidden: { type: Boolean, default: false },
})
const inputValue = reactive({})
const emit = defineEmits(['update:modelValue', 'setData'])

watch(inputValue, () => {
  if (inputValue[props.id + '[select]'] !== '_other_') {
    inputValue[props.id + '[other]'] = ''
  }
  emit('setData', {
    key: props.name,
    value:
      inputValue[props.id + '[select]'] !== '_other_'
        ? inputValue[props.id + '[select]']
        : inputValue[props.id + '[other]'],
  })
  emit(
    'update:modelValue',
    inputValue[props.id + '[select]'] !== '_other_'
      ? { [props.id]: inputValue[props.id + '[select]'] }
      : { [props.id]: inputValue[props.id + '[other]'] },
  )
})
</script>
