<template>
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.2071 7.79289C17.8166 7.40237 17.1834 7.40237 16.7929 7.79289L10.4289 14.1569C10.0384 14.5474 10.0384 15.1805 10.4289 15.5711C10.8195 15.9616 11.4526 15.9616 11.8431 15.5711L17.5 9.91421L23.1569 15.5711C23.5474 15.9616 24.1805 15.9616 24.5711 15.5711C24.9616 15.1805 24.9616 14.5474 24.5711 14.1569L18.2071 7.79289ZM18.5 27.5L18.5 8.5L16.5 8.5L16.5 27.5L18.5 27.5Z"
      fill="white"
    />
  </svg>
</template>
