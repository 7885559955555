<template>
  <div class="flex flex-col space-y-2">
    <div class="font-text font-sans text-black lg:text-lg">
      {{ title }}
      <span v-if="required">*</span>
    </div>
    <select
      :name="name"
      :hidden="hidden"
      :disabled="disabled"
      :required="required"
      :value="modelValue"
      class="max-h-[60px] w-full flex-1 rounded-md border border-blue-dark p-3 leading-none outline-0 focus:border-blue-dark focus:border-transparent focus:ring-0 focus-visible:border-blue-dark disabled:border-[#ADBACB] disabled:bg-[#D8D8D8] lg:p-4 lg:text-xl lg:leading-none"
      @change="e => update(e, name)"
    >
      <option value>- {{ $t('select') }} -</option>
      <option v-for="item in Object.entries(options)" :key="item" :value="item[0]">
        {{ item[1] }}
      </option>
    </select>
  </div>
</template>

<script setup>
defineProps({
  id: String,
  title: String,
  name: String,
  modelValue: String,
  options: Object,
  required: Boolean,
  disabled: { type: Boolean, default: false },
  hidden: { type: Boolean, default: false },
})
const emit = defineEmits(['update:modelValue', 'setData'])
const update = (e, key) => {
  emit('setData', { key, value: e.target.value })
  emit('update:modelValue', e.target.value)
}
</script>
