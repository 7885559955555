import { ref, reactive } from 'vue'

export const formData = reactive<{ data: any }>({ data: {} })

export const useForm = (config: { cmsBasePath: string }) => {
  const isLoading = ref(false)
  const error = ref('')

  const sendForm = async (formId: string, data: any) => {
    error.value = ''
    isLoading.value = true

    const response = await fetch('/api/formSubmit', {
      method: 'POST',
      body: JSON.stringify({
        webform_id: formId,
        ...data,
      }),
    })
    const dataResponse = await response.json()
    if (dataResponse.statusCode >= 400) {
      error.value = dataResponse.statusMessage
      isLoading.value = false
      return false
    }

    isLoading.value = false
    return true
  }

  const uploadFile = async ({
    file,
    webformId,
    fieldName,
  }: {
    file: any
    webformId: string
    fieldName: string
  }) => {
    try {
      const filename = file.name
        .replace(/[^\w -.]/g, '')
        .replace(/\s+/g, '-')
        .toLowerCase()

      const response = await fetch(
        `${config.cmsBasePath}/webform_rest/${webformId}/upload/${fieldName}?_format=json`,
        {
          headers: {
            'Content-Type': 'application/octet-stream',
            'Content-Disposition': `file; filename="${filename}"`,
          },
          method: 'POST',
          body: file,
        },
      )
      const result = await response.json()
      return result
    } catch (error) {
      console.log(error)
    }
  }

  function isObject(item: any) {
    return typeof item === 'object' && !Array.isArray(item) && item !== null
  }

  const updateDataForm = (formId: string, data: any) => {
    formData.data[formId] = data
  }

  // Returns substring with the element selector.
  function getElementName(string: string) {
    const match = string.match(/\[name=["|']([A-z][A-z\d-_.:]*)["|']\]$/)
    return match && match[1]
  }

  // Checks if the condition is true or not and returns a boolean value.
  function getConditionState(trigger: any, value: any) {
    if (isObject(trigger[Object.keys(trigger)[0]])) {
      switch (Object.keys(trigger[Object.keys(trigger)[0]])[0]) {
        case 'pattern': {
          if (typeof value !== 'string') {
            return false
          }

          const re = new RegExp(trigger['value']['pattern'])
          return re.test(value)
        }
        case '!pattern': {
          if (typeof value !== 'string') {
            return false
          }

          const re = new RegExp(trigger['value']['pattern'])
          return !re.test(value)
        }
        case 'less':
          return value < trigger['value']['less']
        case 'less_equal':
          return value <= trigger['value']['less_equal']
        case 'greater':
          return value > trigger['value']['greater']
        case 'greater_equal':
          return value >= trigger['value']['greater_equal']
        case 'between':
        case '!between': {
          const betweenValues = trigger['value']['between']
          const min = betweenValues.substring(0, betweenValues.indexOf(':'))
          const max = betweenValues.substring(betweenValues.indexOf(':'), betweenValues.length - 1)
          if (Object.keys(trigger)[0] == 'between') {
            return value >= min && value <= max
          } else {
            return value < min || value > max
          }
        }
      }
    } else {
      switch (trigger && Object.keys(trigger)[0]) {
        case 'empty':
          return !value
        case 'filled':
          return !!value
        case 'checked':
          return value == true
        case 'unchecked':
          return value == false
        case 'value':
          return value == trigger['value']
        case '!value':
          return value != trigger['value']
      }
    }
  }

  const getStateConditions = (states: any, data: any) => {
    const allConditionsForTrue: any = {}
    const anyConditionForTrue: any = {}
    const oneConditionForTrue: any = {}
    let webformStates

    if (states && !Object.keys(states).includes('headers')) {
      webformStates = states
      for (const effect of Object.keys(webformStates)) {
        allConditionsForTrue[effect] = {}
        anyConditionForTrue[effect] = {}
        oneConditionForTrue[effect] = {}
        // Loop through indexes or selectors.
        for (const indexOrSelector of Object.keys(webformStates[effect])) {
          // If indexOrSelector is not an index, then ALL conditions must be true
          // for the attribute to take effect.
          if (isNaN(Number(indexOrSelector))) {
            const elementName: any = getElementName(indexOrSelector)

            if (
              Object.prototype.hasOwnProperty.call(data, elementName) &&
              typeof data[elementName] !== 'string' &&
              typeof data[elementName] !== 'boolean'
            ) {
              console.warn(
                `Unexpected type "${typeof data[elementName]}" for element "${elementName}"`,
              )
              continue
            }

            allConditionsForTrue[effect][elementName] = getConditionState(
              webformStates[effect][indexOrSelector],
              data[elementName] ?? undefined,
            )
          } else if (
            webformStates[effect][indexOrSelector] !== 'or' &&
            webformStates[effect][indexOrSelector] !== 'xor'
          ) {
            const selectorString = Object.keys(webformStates[effect][indexOrSelector])[0]
            const elementName: any = getElementName(selectorString)
            const trigger = Object.values(webformStates[effect][indexOrSelector])[0]

            if (
              Object.prototype.hasOwnProperty.call(data, elementName) &&
              typeof data[elementName] !== 'string' &&
              typeof data[elementName] !== 'boolean'
            ) {
              console.warn(
                `Unexpected type "${typeof data[elementName]}" for element "${elementName}"`,
              )
              continue
            }

            if (Object.values(webformStates[effect]).includes('or')) {
              anyConditionForTrue[effect][elementName] = getConditionState(
                trigger,
                data[elementName] ?? undefined,
              )
            } else if (Object.values(webformStates[effect]).includes('xor')) {
              oneConditionForTrue[effect][elementName] = getConditionState(
                trigger,
                data[elementName] ?? undefined,
              )
            }
          }
        }
      }
    }
    return {
      allConditionsForTrue,
      anyConditionForTrue,
      oneConditionForTrue,
      webformStates,
    }
  }

  const getEffect = (effect: any) => {
    switch (effect) {
      case 'invisible':
      case 'invisible-slide':
      case '!visible':
        return { '#access': false }
      case 'visible':
      case 'visible-slide':
      case '!invisible':
        return { '#access': true }
      case 'enabled':
      case '!disabled':
        return { '#disabled': false }
      case 'disabled':
      case '!enabled':
        return { '#disabled': true }
      case 'required':
      case '!optional':
        return { '#required': true }
      case 'optional':
      case '!required':
        return { '#required': false }
      case 'checked':
      case '!unchecked':
        return { '#checked': true }
      case 'unchecked':
      case '!checked':
        return { '#unchecked': true }
      case 'readonly':
      case '!readwrite':
        return { '#readonly': true }
      case 'readwrite':
      case '!readonly':
        return { '#readonly': false }
    }
  }

  const getStatesForData = (states: any, data: any) => {
    // Initialize the list of conditions and set whether they are true or not.
    const { allConditionsForTrue, anyConditionForTrue, oneConditionForTrue, webformStates } =
      getStateConditions(states, data)

    if (
      !webformStates ||
      Object.keys(webformStates).length === 0 ||
      !Object.keys(webformStates)[0].length
    ) {
      return { '#access': true }
    }

    const effects = {}

    // Set the state accordingly based on our list of conditions.
    for (const effect of Object.keys(webformStates)) {
      // Check if EVERY condition is true.
      if (
        Object.keys(allConditionsForTrue[effect]).length &&
        Object.values(allConditionsForTrue[effect]).every(value => value === true)
      ) {
        Object.assign(effects, getEffect(effect))
        // Check if ANY condition is true (OR).
      } else if (
        Object.keys(anyConditionForTrue[effect]).length &&
        Object.values(anyConditionForTrue[effect]).includes(true)
      ) {
        Object.assign(effects, getEffect(effect))
        // Check if ONE condition is true (XOR).
      } else if (Object.keys(oneConditionForTrue[effect]).length) {
        const filterByTrue = Object.values(oneConditionForTrue[effect]).filter(item => item == true)
        if (filterByTrue.length == 1) {
          Object.assign(effects, getEffect(effect))
        } else {
          const falseEffect = '!' + effect
          Object.assign(effects, getEffect(falseEffect))
        }
      } else {
        const falseEffect = '!' + effect
        Object.assign(effects, getEffect(falseEffect))
      }
    }
    return effects
  }

  return {
    isLoading,
    sendForm,
    error,
    updateDataForm,
    getStatesForData,
    uploadFile,
  }
}
