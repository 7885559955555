export default function deepClone(o: any) {
  if (typeof o !== 'object') {
    return o
  }
  if (!o) {
    return o
  }

  // https://jsperf.com/deep-copy-vs-json-stringify-json-parse/25
  if (Array.isArray(o)) {
    const newO = []
    for (let i = 0; i < o.length; i += 1) {
      const val: any = !o[i] || typeof o[i] !== 'object' ? o[i] : deepClone(o[i])
      newO[i] = val === undefined ? null : val
    }
    return newO
  }

  const newO: any = {}
  for (const i of Object.keys(o)) {
    const val = !o[i] || typeof o[i] !== 'object' ? o[i] : deepClone(o[i])
    if (val === undefined) {
      continue
    }
    newO[i] = val
  }
  return newO
}
