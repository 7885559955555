<template>
  <div class="flex flex-1 flex-col">
    <label
      class="mb-1.5 inline-block font-sans text-base text-black lg:mb-3 lg:text-lg lg:leading-snug"
      :class="styleLabel"
    >
      {{ label }}
      <span v-if="required">*</span>
    </label>
    <div class="relative">
      <textarea
        class="flex-1 rounded-md border-transparent p-3 leading-none focus:border-transparent focus:ring-0 lg:p-4 lg:text-xl lg:leading-none"
        :class="styleInput"
        :rows="rows"
        :placeholder="placeholder"
        :name="name"
        :hidden="hidden"
        :disabled="disabled"
        :read-only="readOnly"
        :required="required"
        v-model="modelValueModel"
      />

      <div
        v-if="v$?.$invalid && v$?.$dirty && v$?.$errors?.length > 0"
        class="absolute mt-0.5 flex w-full flex-col items-start rounded px-1 py-0 pr-3 font-sans text-sm text-pink"
        :class="styleError"
        role="alert"
      >
        <span v-for="err in v$.$errors" :key="err.$uid">{{ err.$message }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useVModel } from '@vueuse/core'

const props = defineProps({
  label: String,
  styleInput: String,
  styleLabel: String,
  styleError: String,
  rows: { type: Number, default: 10 },
  placeholder: String,
  modelValue: String,
  name: String,
  required: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  hidden: { type: Boolean, default: false },
  readOnly: { type: Boolean, default: false },
  v$: {
    type: Object,
    default: undefined,
  },
})
const emits = defineEmits(['update:modelValue'])
const modelValueModel = useVModel(props, 'modelValue', emits)
</script>
