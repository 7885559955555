<template>
  <div class="mt-4">
    <DonationCounter form-id="eurotour-crowdfunding" class="mb-4 lg:mb-4" />
    <p class="my-4 hidden font-sans text-base lg:block">
      {{ $t('choose-a-sum-to-contribute') }}
    </p>
    <div class="flex space-x-2 lg:space-x-4">
      <Button
        v-for="(suma, index) in summa"
        :key="index"
        :title="`${suma.value} ${suma.currency}`"
        class="flex-1 !py-2 lowercase lg:!py-2 lg:leading-none"
        class-name-text="text-sm lg:text-base "
        :type="summaSelected === suma.value ? undefined : 'outline'"
        @click="handleClickSumma(suma.value)"
      />
      <Button
        :title="$t('other')"
        class="flex-1 !py-2 lowercase lg:!py-2 lg:leading-none"
        class-name-text="text-sm lg:text-base "
        :type="otherSum ? undefined : 'outline'"
        @click="handleClickOther"
      />
    </div>

    <Input
      v-show="otherSum"
      v-model="summaSelected"
      type="number"
      :min="0"
      :label="$t('your-amount-to-donate')"
      style-input="border !border-blue-dark focus:border-blue-dark shadow-[0_12px_33px_0_rgba(5, 58, 65, 0.04)] text-blue text-sm lg:text-base font-bold !pr-10 w-40"
      :currency="$t('currency')"
      class="mt-6 items-start"
      :right="otherRightString"
    />
  </div>
  <Link
    :title="$t('next')"
    color="pink"
    class="mt-8 w-full lg:w-auto"
    :link="urlSelected + summaSelected"
    :external="true"
  />
</template>

<script setup>
/** Single donation form */
import { ref } from 'vue'
import DonationCounter from '@/components/molecules/DonationCounter.vue'

import { Input, Button, Link } from 'refresh-ui'
import { useI18n } from '#imports'
const { t } = useI18n()

const otherRightString = `<span class='absolute right-4 bottom-3 lg:bottom-4 text-sm font-bold text-blue lg:text-base'>${t(
  'currency',
)}</span>`
const summa = [
  { value: 200, currency: t('currency') },
  { value: 100, currency: t('currency') },
  { value: 75, currency: t('currency') },
]

const urlSelected = ref(t('component-form.donation_counter.url'))
const summaSelected = ref(summa[1].value)
const otherSum = ref(false)

const handleClickSumma = suma => {
  summaSelected.value = suma
  otherSum.value = false
}

const handleClickOther = () => {
  otherSum.value = true
  summaSelected.value = 0
}
</script>
