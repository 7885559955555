import Personnummer from 'personnummer'
import { parsePhoneNumber } from 'awesome-phonenumber'
import type { Address, Person } from 'shared-types'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)
// TODO: Fix vuelidate typings here, primarily siblings, they aren't just "Address" or "Person"
// https://vuelidate-next.netlify.app/custom_validators.html#accessing-property-parent-object

export const postcodeDependingOnCountry = (value: string, siblings: Address) => {
  if (siblings.countryCode === 'SE' && value.length !== 5) return false
  if (siblings.countryCode !== 'SE') return true
  return true
}

export const ssnValidOrHasBirthDate = (value: string, siblings: Person) => {
  /** Requires that the ssn is either valid or that there is a birthDate */
  if (Personnummer.valid(value)) return true
  if (siblings.birthDate) return true
  return false
}

export const birthDateOrHasSsn = (value: string, siblings: Person) => {
  /** Requires that the birthDate is either valid or that there is a ssn */
  if (dayjs(value, 'YYYY-MM-DD', true).isValid()) return true
  if (Personnummer.valid(siblings.ssn)) return true
  return false
}

export const birthDate = (value: string) => {
  if (!value) return true
  return dayjs(value, 'YYYY-MM-DD', true).isValid()
}

export const ssnValid = (value: string) => {
  if (Personnummer.valid(value)) return true
  return false
}

export const swedishMobile = (value: string) => {
  if (!value) return true
  const stripped = value.replace(/-|\s/g, '')
  const phoneNumber = parsePhoneNumber(stripped, { regionCode: 'SE' })
  return phoneNumber.valid && phoneNumber.regionCode === 'SE'
}

export const intMobile = (value: string) => {
  if (!value) return true
  const stripped = value.replace(/-|\s/g, '')
  const phoneNumber = parsePhoneNumber(stripped)
  return phoneNumber.valid
}

export const countryCode = (value: string) => {
  if (!value) return true
  if (value === 'NONE') return false
  return true
}

export const isValidDate = (date: string) => {
  return dayjs(date, 'YYYY-MM-DD', false).isValid()
}

export const isChild = (date: string) => {
  const dateNow = dayjs(new Date())
  return dateNow.diff(date, 'year') <= 13
}

export const isAdult = (date: string) => {
  const dateNow = dayjs(new Date())
  return dateNow.diff(date, 'year') >= 14
}
