<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="2" y="3" width="20" height="2" rx="1" fill="#053A41" />
    <rect x="2" y="11" width="20" height="2" rx="1" fill="#053A41" />
    <rect x="2" y="19" width="20" height="2" rx="1" fill="#053A41" />
  </svg>
</template>
