<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <!-- <Spotify /> -->
    <div class="mx-auto flex max-w-[650px] flex-col items-center text-center">
      <SubTitle class="text-blue">{{ data?.subtitle }}</SubTitle>
      <Title>{{ data?.title }}</Title>
      <div
        v-if="data?.copy"
        class="mt-9 max-w-2xl hyphens-auto px-8 text-center font-sans text-base text-black lg:mb-12 lg:px-0 lg:text-lg [&_p]:hyphens-auto"
        v-html="data?.copy"
      ></div>
    </div>

    <div class="relative mx-auto max-w-[1920px]">
      <ClientOnly>
        <Swiper
          :modules="[Controller, Navigation, Pagination]"
          :controller="{ control: controlledSwiper }"
          :allow-touch-move="false"
          :slides-per-view="3"
          :space-between="0"
          :centered-slides="data?.podcasts?.length === 1 ? true : false"
          :pagination="false"
          :breakpoints="breakpoints"
          :loop="false"
          class="podcast"
          @swiper="setSwiper"
          @slide-change="onSlideChange"
        >
          <SwiperSlide v-for="podcast in data?.podcasts" :key="podcast">
            <PodcastCard
              :id="podcast"
              :contoller="spotifyStore.embedController"
              :current-podcast="spotifyStore.currentPodcast"
              :duration="spotifyStore.duration"
              :current-seconds="spotifyStore.currentSeconds"
              :is-paused="spotifyStore.isPaused"
              :is-buffering="spotifyStore.isBuffering"
              @set-podcast="setCurrentPodcast"
            />
          </SwiperSlide>
        </Swiper>
        <SliderPagination
          v-if="data?.podcasts?.length > 1"
          :slider="controlledSwiper"
          :slides="data?.podcasts?.length"
          :class="[data?.podcasts?.length <= 3 && 'min-[1920px]:hidden']"
          :active-index="realIndex"
        />
        <SliderNavigation
          v-if="data?.podcasts?.length > 1"
          :class="[data?.podcasts?.length <= 3 && 'min-[1920px]:hidden']"
          :slider="controlledSwiper"
        />
      </ClientOnly>
    </div>
    <div v-if="data?.link" class="mt-12 flex flex-col items-center lg:mt-16">
      <Link
        :title="data?.link?.title"
        :link="data?.link?.url"
        type="outline"
        :external="data.link?.type === 'ext'"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Swiper as SwiperType } from 'swiper'
import { Controller, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { ref, watch, nextTick } from 'vue'
import type { Ref } from 'vue'
import PodcastCard from '../molecules/PodcastCard.vue'
import SliderNavigation from '../atoms/SliderNavigation.vue'
import SliderPagination from '../atoms/SliderPagination.vue'
import Title from '../atoms/Title.vue'
import SubTitle from '../atoms/SubTitle.vue'
import Link from '../atoms/Link.vue'
import type { ILinkData } from '../../types'

import { spotifyStore } from '../../composables/useSpotify'

export interface IPodcastsBlock {
  id: string
  subtitle?: string
  title?: string
  copy?: string
  link?: ILinkData
  podcasts: string[]
}

interface Props {
  data: IPodcastsBlock
}

const props = defineProps<Props>()

const controlledSwiper: Ref<SwiperType | undefined> = ref()
const realIndex = ref(0)

const breakpoints = {
  320: {
    slidesPerView: 1,
    spaceBetween: 30,
  },
  1200: {
    slidesPerView: 2,
    spaceBetween: 30,
  },
  1900: {
    slidesPerView: props.data.podcasts?.length === 2 ? 2 : 3,
    spaceBetween: 50,
  },
}

const setSwiper = (swiper: SwiperType) => {
  controlledSwiper.value = swiper
  realIndex.value = controlledSwiper?.value?.realIndex
}
const onSlideChange = () => {
  realIndex.value = controlledSwiper?.value?.realIndex ?? 0
}

const setCurrentPodcast = (id: string) => {
  spotifyStore.currentPodcast = id
}

watch(
  () => spotifyStore.currentPodcast,
  () => {
    spotifyStore.currentSeconds = 0
    spotifyStore.duration = 0
  },
)

watch(
  () => spotifyStore.currentSeconds,
  async () => {
    if (spotifyStore.currentSeconds === 0 || spotifyStore.duration === 0) {
      return
    }
    if (spotifyStore.duration - spotifyStore.currentSeconds < 3) {
      spotifyStore.isPaused = true
      spotifyStore.currentSeconds = 0
      await nextTick()
      const indexPodcast = props.data?.podcasts.indexOf(spotifyStore.currentPodcast)
      if (props.data?.podcasts?.length - 1 !== indexPodcast) {
        spotifyStore.currentPodcast = props.data?.podcasts[indexPodcast + 1]
        spotifyStore.embedController?.loadUri(
          'spotify:episode:' + props.data?.podcasts[indexPodcast + 1],
        )
        await nextTick()
        spotifyStore.embedController?.play()
        await nextTick()
        controlledSwiper.value?.slideNext(500)
      }
    }
  },
)
</script>

<style>
.podcast.swiper {
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
}
</style>
