<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <div class="relative">
      <Container class="mb-11 !max-w-[650px] text-center lg:mb-16">
        <SubTitle v-if="data?.subtitle" class="text-blue">{{ data?.subtitle }}</SubTitle>
        <Title v-if="data?.title" custom-style="mb-6 leading-none lg:mb-9">
          {{ data?.title }}
        </Title>
        <div class="text-center font-sans text-base lg:text-lg" v-html="data?.copy"></div>
      </Container>
      <ClientOnly>
        <Swiper
          :modules="[Controller, Navigation, Virtual]"
          :controller="{ control: controlledSwiper }"
          :slides-per-view="4"
          :space-between="30"
          :centered-slides="true"
          :loop="loop"
          :breakpoints="breakpoints"
          @swiper="setSwiper"
          @slide-change="onSlideChange"
          @slider-move="startMove"
          @transition-start="startMove"
          @transition-end="stopMove"
          @slide-change-transition-end="stopMove"
          :initial-slide="initSlide"
          virtual
        >
          <div
            class="absolute bottom-[100px] right-0 top-0 z-30 w-[10%] bg-gradient-to-r from-transparent transition-all duration-300 group-[.is-blue]:to-blue-lighter group-[.is-white]:to-white"
          ></div>
          <div
            class="absolute bottom-[100px] left-0 top-0 z-30 hidden w-[10%] bg-gradient-to-l from-transparent transition-all duration-300 group-[.is-blue]:to-blue-lighter group-[.is-white]:to-white lg:block"
          ></div>

          <SwiperSlide
            v-for="(item, index) in cards"
            v-slot="{ isActive, isPrev, isNext }"
            :key="index"
            :virtualIndex="index"
          >
            <div
              class="relative flex aspect-[0.68] flex-col items-center justify-center overflow-hidden duration-500"
            >
              <div
                :class="[
                  isActive ? 'scale-100' : 'scale-[0.85]',
                  !isNext && prevPrev === index && '-translate-x-full lg:translate-x-0',
                  isPrev && '-translate-x-full lg:mr-[10%] lg:translate-x-0',
                  isNext && 'lg:ml-[10%]',
                ]"
                class="relative flex h-full w-full flex-col justify-between overflow-hidden rounded-2xl transition-all duration-500"
              >
                <NuxtLink
                  :to="removeCmsDomain(item.link)"
                  :target="item.link?.type === 'ext' ? '_blank' : '_self'"
                >
                  <NuxtImg
                    v-if="item?.image?.type && item?.image?.type === 'image'"
                    :src="item.image.url"
                    :alt="item.image.alt || item.title"
                    class="absolute h-full w-full object-cover"
                    loading="lazy"
                  />
                  <NuxtImg
                    v-else-if="item?.image"
                    :src="'c_fill,h_700,w_600/f_webp/' + item.image.url"
                    provider="cmsProvider"
                    :alt="item.image.alt || item.title"
                    class="absolute h-full w-full object-cover"
                    loading="lazy"
                  />
                </NuxtLink>
                <div class="absolute px-4 py-5">
                  <BadgeCategory v-if="item?.category?.title" :path="item?.category?.path">
                    {{ item?.category?.title }}
                  </BadgeCategory>
                </div>

                <NuxtLink
                  :to="removeCmsDomain(item.link)"
                  :target="item.link?.type === 'ext' ? '_blank' : '_self'"
                  class="relative m-6 cursor-pointer rounded-md bg-blue-union p-4 text-base text-white before:absolute before:-top-2 before:block before:h-4 before:w-4 before:rotate-45 before:bg-blue-union before:content-[''] lg:text-xl"
                >
                  {{ item.title }}
                </NuxtLink>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <SliderPagination
          v-if="loop"
          :slider="controlledSwiper"
          :slides="cards?.length || 0"
          :active-index="realIndex"
        />
        <SliderNavigation :slider="controlledSwiper" />
      </ClientOnly>
    </div>
    <div class="flex justify-center">
      <Link
        v-if="data?.link?.url"
        type="outline"
        :title="data?.link?.title"
        :link="data?.link?.url"
        :external="data.link?.type === 'ext'"
        class="mt-16 lg:mt-20"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { removeCmsDomain } from '../../utils/urlFilter'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { ref, computed, watch } from 'vue'
import { Swiper as SwiperType } from 'swiper'
import { Controller, Navigation, Virtual } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { useWindowSize } from '@vueuse/core'
import type { Ref } from 'vue'

import Container from '../atoms/Container.vue'
import SubTitle from '../atoms/SubTitle.vue'
import Title from '../atoms/Title.vue'
import SliderNavigation from '../atoms/SliderNavigation.vue'
import Link from '../atoms/Link.vue'
import BadgeCategory from '../atoms/BadgeCategory.vue'
import SliderPagination from '../atoms/SliderPagination.vue'
import NuxtImg from '../atoms/NuxtImg.vue'
import type { ICardData, ILinkData } from '../../types'

export interface INewsCardSliderBlock {
  id: string
  subtitle?: string
  title?: string
  copy?: string
  link?: ILinkData
  cards?: ICardData[]
}

interface Props {
  data?: INewsCardSliderBlock
  loop?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  loop: () => true,
})

const { width } = useWindowSize()

const controlledSwiper: Ref<SwiperType | undefined> = ref()

const realIndex = ref(0)
const showShadow = ref(true)

const breakpoints = {
  320: {
    slidesPerView: 1.3,
    spaceBetween: 0,
  },
  768: {
    slidesPerView: 2.5,
    spaceBetween: 50,
  },
  1200: {
    slidesPerView: 4,
    spaceBetween: 30,
  },
}

const widthScreen = computed(() => width.value)

const cards = computed(() => {
  const length = props.data?.cards ? props.data.cards.length : 0

  return length < 4
    ? Array(10).fill(props.data?.cards).flat()
    : props.loop
      ? Array(20).fill(props.data?.cards).flat()
      : props.data?.cards
})

const initSlide = computed(() =>
  !props.loop ? 0 : cards.value?.length ? cards.value?.length / 2 : 0,
)

watch(widthScreen, () => {
  controlledSwiper.value && controlledSwiper.value.update()
})

const startMove = () => {
  showShadow.value = false
}
const stopMove = () => {
  showShadow.value = true
}

const prevPrev = computed(() => {
  const length = props.data?.cards ? props.data.cards.length : 0
  const i = realIndex.value - 2
  return i >= 0 ? i : length + i
})

const setSwiper = (swiper: SwiperType) => {
  controlledSwiper.value = swiper
  realIndex.value = controlledSwiper?.value?.realIndex
}
const onSlideChange = () => {
  realIndex.value = controlledSwiper?.value?.realIndex ?? 0
}
</script>

<style>
.swiper {
  padding-bottom: 70px;
}
.swiper-pagination {
  margin-top: 70px;
}
</style>
