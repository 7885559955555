import deepClone from '../../utils/deepClone'
import { getRelationships } from './getRelationships'

export type getLinkReturnType = { type: string; title: string; url: string } | undefined

export const getLink = (data: any): getLinkReturnType => {
  if (!data) return

  const copy = deepClone(data)
  const id = Array.isArray(copy) && copy.length > 0 ? copy[0].id : copy?.id

  if (copy.length === 0) return

  const link = getRelationships(id)

  return {
    type: link?.attributes?.field_link_type,
    title: link?.attributes?.field_link_text,
    url:
      link?.attributes?.field_link_type === 'ext'
        ? link?.attributes?.field_link_ext?.uri
        : link?.attributes?.computed_link_int,
  }
}
