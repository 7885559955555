<template>
  <svg
    width="155"
    height="95"
    viewBox="0 0 155 95"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.5"
      d="M150.657 17.9334C145.305 9.00283 129.004 -5.55827 96.1569 7.25028C87.8274 10.4571 79.7429 14.8012 70.8668 11.8181C63.065 9.20791 50.1373 5.55366 42.5993 2.49601C30.9154 -2.10909 16.5743 -0.0395918 10.2612 6.22485C6.41681 10.1401 -11.8063 29.1385 14.3317 59.0065C34.7032 82.4981 84.0584 94.188 96.6092 94.8965C96.967 94.9156 97.3255 94.9156 97.6833 94.8965C100.491 95.0457 102.131 94.9897 102.131 94.9897C107.351 94.8406 85.1702 84.5117 83.738 82.2744C93.9143 80.8388 148.621 73.9964 154.2 35.664C155.132 29.5262 153.879 23.2586 150.657 17.9334Z"
      fill="white"
    />
  </svg>
</template>
