<template>
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.1203 8.49882C19.8859 6.70561 19.8859 3.80156 18.1203 2.00834C16.5578 0.421429 14.0953 0.21513 12.2984 1.51957L12.2484 1.55449C11.7984 1.88139 11.6953 2.51616 12.0172 2.97001C12.3391 3.42387 12.9641 3.53178 13.4109 3.20488L13.4609 3.16996C14.4641 2.44316 15.8359 2.55742 16.7047 3.44291C17.6891 4.44267 17.6891 6.06132 16.7047 7.06108L13.1984 10.6285C12.2141 11.6282 10.6203 11.6282 9.63594 10.6285C8.76406 9.74296 8.65156 8.34965 9.36719 7.33403L9.40156 7.28325C9.72344 6.82621 9.61719 6.19145 9.17031 5.86772C8.72344 5.54399 8.09531 5.64872 7.77656 6.10258L7.74219 6.15336C6.45469 7.97514 6.65781 10.4761 8.22031 12.063C9.98594 13.8562 12.8453 13.8562 14.6109 12.063L18.1203 8.49882ZM1.88281 7.75615C0.117187 9.54936 0.117187 12.4534 1.88281 14.2466C3.44531 15.8335 5.90781 16.0398 7.70469 14.7354L7.75469 14.7005C8.20469 14.3736 8.30781 13.7388 7.98594 13.285C7.66406 12.8311 7.03906 12.7232 6.59219 13.0501L6.54219 13.085C5.53906 13.8118 4.16719 13.6976 3.29844 12.8121C2.31406 11.8091 2.31406 10.1905 3.29844 9.19072L6.80469 5.62651C7.78906 4.62675 9.38281 4.62675 10.3672 5.62651C11.2391 6.51201 11.3516 7.90532 10.6359 8.92412L10.6016 8.9749C10.2797 9.43193 10.3859 10.0667 10.8328 10.3904C11.2797 10.7142 11.9078 10.6094 12.2266 10.1556L12.2609 10.1048C13.5484 8.27983 13.3453 5.77885 11.7828 4.19194C10.0172 2.39872 7.15781 2.39872 5.39219 4.19194L1.88281 7.75615Z"
      fill="currentColor"
    />
  </svg>
</template>
