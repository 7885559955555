<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="margin: auto; background: none; display: block; shape-rendering: auto"
    width="100px"
    height="100px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <rect x="15" y="25" width="20" height="50" fill="#00b4d2">
      <animate
        attributeName="y"
        repeatCount="indefinite"
        dur="0.9345794392523364s"
        calcMode="spline"
        keyTimes="0;0.5;1"
        values="12.5;25;25"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
        begin="-0.18691588785046728s"
      ></animate>
      <animate
        attributeName="height"
        repeatCount="indefinite"
        dur="0.9345794392523364s"
        calcMode="spline"
        keyTimes="0;0.5;1"
        values="75;50;50"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
        begin="-0.18691588785046728s"
      ></animate>
    </rect>
    <rect x="40" y="25" width="20" height="50" fill="#00b4d2">
      <animate
        attributeName="y"
        repeatCount="indefinite"
        dur="0.9345794392523364s"
        calcMode="spline"
        keyTimes="0;0.5;1"
        values="15.625;25;25"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
        begin="-0.09345794392523364s"
      ></animate>
      <animate
        attributeName="height"
        repeatCount="indefinite"
        dur="0.9345794392523364s"
        calcMode="spline"
        keyTimes="0;0.5;1"
        values="68.75;50;50"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
        begin="-0.09345794392523364s"
      ></animate>
    </rect>
    <rect x="65" y="25" width="20" height="50" fill="#00b4d2">
      <animate
        attributeName="y"
        repeatCount="indefinite"
        dur="0.9345794392523364s"
        calcMode="spline"
        keyTimes="0;0.5;1"
        values="15.625;25;25"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
      ></animate>
      <animate
        attributeName="height"
        repeatCount="indefinite"
        dur="0.9345794392523364s"
        calcMode="spline"
        keyTimes="0;0.5;1"
        values="68.75;50;50"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
      ></animate>
    </rect>
  </svg>
</template>
