import crypto from 'crypto'
import { z } from 'zod'
import { flagError } from 'error-handling'
import type { ApiResponse, SwishCreateMCommercePaymentRequestObject } from './types'
import initializeSwish from './'

const schemaMcommerceEvent = z.object({
  amount: z.string(),
  payeePaymentReference: z.string(),
  message: z.string().optional(),
  currency: z.string().optional(),
  callbackUrl: z.string(),
})

export type McommerceRequest = z.infer<typeof schemaMcommerceEvent>

export interface ApiCreatePayment extends ApiResponse {
  operationId: string
  paymentRequestToken: string
  qr: string
  swishAppUrl: string
}

interface IMCommerce {
  config: any
  paymentData: any
}

async function mCommerce({ config, paymentData }: IMCommerce): Promise<undefined | any> {
  try {
    const {
      payeePaymentReference,
      amount = '100',
      currency = 'SEK',
      message = 'Donation',
      callbackUrl = '',
    }: McommerceRequest = paymentData

    const uuid = crypto.randomUUID().replace(/\-/g, '').toUpperCase() // TODO: Check if this is an arbitrary id from us, if that's the case, we maybe we could send the crm transaction id?

    const swish = initializeSwish(config)

    const data = {
      payeePaymentReference,
      callbackUrl,
      amount,
      currency,
      message,
    }

    const response = await swish.createPaymentRequest<SwishCreateMCommercePaymentRequestObject>(
      uuid,
      data,
    )

    const qr = await swish.createQrCode(response?.token as string)

    return {
      operationId: uuid,
      qr,
      swishAppUrl: `swish://paymentrequest?token=${response?.token}&callbackurl=${callbackUrl}?ref=${uuid}`,
    }
  } catch (e) {
    flagError(e, 'm-commerce')
    return undefined
  }
}

export default mCommerce
