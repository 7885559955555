import deepClone from '../../utils/deepClone'
import { getRelationships } from './getRelationships'

export type GetVideoReturnType =
  | { type: 'video'; name: string; url: string; file: string }
  | undefined

export const getVideo = (data: any): GetVideoReturnType => {
  if (!data) return

  // console.log('prev data', data)
  const copy = deepClone(data)

  if (Array.isArray(copy) && copy.length === 0) return

  const id: string = Array.isArray(copy) && copy.length > 0 ? copy[0].id : copy?.id

  const video = getRelationships(id, {})
  // console.log('video', JSON.stringify(video, null, 2))

  // Check if we have an uploaded video
  let file = ''
  if (video?.relationships?.field_media_video_file?.data?.id) {
    const fileData = getRelationships(video.relationships.field_media_video_file.data.id, {})
    file = fileData?.attributes?.uri?.url || ''
  }
  return {
    type: 'video',
    name: video?.attributes?.name,
    url: video?.attributes?.field_media_oembed_video,
    file: file,
  }
}
