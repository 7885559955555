<template>
  <button
    class="relative flex items-center justify-center rounded-md px-2 py-3 font-sans text-base font-bold uppercase outline-0 transition disabled:cursor-not-allowed disabled:border-[#ADBACB] disabled:bg-[#ADBACB] disabled:hover:text-white lg:py-4 lg:text-xl"
    :class="buttonClass"
    :type="typeButton"
  >
    <div
      v-show="isLoading"
      class="loader absolute left-0 right-0 m-auto h-6 w-6 animate-spin rounded-full border-4 border-t-4 border-gray-200 border-t-blue ease-linear"
    ></div>
    <span :class="[classNameText, isLoading && 'blur-sm']">{{ title }}</span>
    <ArrowRight v-if="arrowRight" class="ml-4" />
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import ArrowRight from './Icons/ArrowRight.vue'

interface IButtonProps {
  title: string
  classNameText?: string
  type?: 'fill' | 'outline'
  typeButton?: 'submit' | 'reset' | 'button'
  color?: 'blue' | 'pink'
  isLoading?: boolean
  arrowRight?: boolean
}

const props = withDefaults(defineProps<IButtonProps>(), {
  type: 'fill',
  typeButton: 'button',
  color: 'blue',
  isLoading: false,
  arrowRight: false,
})

const buttonClass = computed(() => ({
  'bg-blue text-white hover:bg-blue-dark border-2 border-blue hover:border-blue-dark shadow-[0_5px_15px_0px_rgba(0,155,181,0.22)] hover:shadow-none':
    props.type === 'fill' && props.color === 'blue',
  'bg-white text-blue border-2 border-blue hover:border-blue-dark hover:text-blue-dark shadow-[0_5px_15px_0px_rgba(0,155,181,0.22)] hover:shadow-none':
    props.type === 'outline' && props.color === 'blue',
  'bg-pink text-white hover:bg-pink-dark border-2 border-pink hover:border-pink-dark shadow-[0_5px_15px_0px_rgba(171,8,98,0.22)] hover:shadow-none':
    props.type === 'fill' && props.color === 'pink',
  'bg-white text-pink border-2 border-pink hover:border-pink-dark hover:text-pink-dark shadow-[0_5px_15px_0px_rgba(171,8,98,0.22)] hover:shadow-none':
    props.type === 'outline' && props.color === 'pink',
}))
</script>
